import {AbstractControl} from "@angular/forms";

export class FormInputUtility {

  static readonly MSISDN_ERROR_MSG = {
    required: $localize`:@@fieldIsRequired:Pole jest wymagane`,
    incorrectMsisdnFormat: $localize`:@@incorrectMsisdn:Ten numer jest niepoprawny`,
    notBelongsToP4: $localize`:@@noPlayNumber:Ten numer nie jest obsługiwany przez `,
    isNotPrepaid: $localize`:@@noPlayCardNumber:Ten numer nie jest w ofercie Na Kartę`
  };

  static readonly AMOUNT_INPUT_MESSAGES = {
    isIncorrect: $localize`:@@fieldIsRequired:Pole jest wymagane`,
    isAmountTooLow: $localize`:@@minRechargeValue:Minimalna kwota doładowania to 5 zł `,
    isAmountTooHigh: $localize`:@@maxRechargeValue:Możesz doładować maksymalnie za 300 zł`
  };

  static readonly EMAIL_ERROR_MSG = {
    required: $localize`:@@fieldIsRequired:Pole jest wymagane`,
    incorrectMailFormat: $localize`:@@wrongEmail:Adres email jest nieprawidłowy`,
  };

  static readonly BLIK_ERROR_MSG = {
    required: $localize`:@@fieldIsRequired:Pole jest wymagane`,
    incorrectBlik: $localize`:@@incorrectBlik:Kod blik jest nieprawidłowy`,
  };

  static isInputInvalid(control: AbstractControl) {
    return control.touched && control.invalid;
  }

  static getErrorMsg(control: AbstractControl, errorMessagesMap) {
    const errors = Object.getOwnPropertyNames(errorMessagesMap)
      .filter(error => control.hasError(error))
      .map(error => errorMessagesMap[error]);
    return (errors.length >= 1) ? errors[0] : '';
  }

}
