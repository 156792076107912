<div id="main-base-page-wrapper" class="page-wrapper page-large theme-base-page page-layer page-large"
     data-page-wrapper *ngIf="isAllContentLoaded()">
  <div id="page-wrapper">
    <div class="content-top">

      <!--<div [innerHTML]="header"></div>-->

      <div class="content-top" *ngIf="restService.isVirgin()">
        <div class="container">
          <div class="topup-header">
            <div class="topup-header-login">
            </div>
            <div class="topup-header-main">
              <a href="https://virginmobile.pl/" class="logo"><img src="/assets/images/logo.6f2d3a71.svg"
                                                                   title="Virgin Mobile" alt="Virgin Mobile logo"></a>
            </div>
            <div class="language-switch" *ngIf="restService.isVirgin()" style="position: inherit">
              <ul class="language-switch__list">
                <li><a href="{{changeLanguage('/pl/')}}"
                       class="language-switch__link language-switch__link--current" aria-current="page" lang="pl"><img
                  src="https://media-play.pl/binaries/_ht_1638524593169/content/gallery/play/portal/html/images/components/language-switch/flag-pl.svg"
                  class="language-switch__flag" alt="wersja polska"></a></li>
                <li><a href="{{changeLanguage('/en/')}}"><img
                  src="https://media-play.pl/binaries/_ht_1638524592548/content/gallery/play/portal/html/images/components/language-switch/flag-gb.svg"
                  class="language-switch__flag" alt="english"></a></li>
                <li><a href="{{changeLanguage('/uk/')}}" class="language-switch__link"
                       lang="uk"><img
                  src="https://media-play.pl/binaries/_ht_1638524593012/content/gallery/play/portal/html/images/components/language-switch/flag-ua.svg"
                  class="language-switch__flag" alt="Українська"></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="language-switch" *ngIf="!restService.isVirgin()">
        <ul class="language-switch__list" style="padding-right: 50px">
          <li><a href="{{changeLanguage('/pl/')}}"
                 class="language-switch__link language-switch__link--current" aria-current="page" lang="pl"><img
            src="https://media-play.pl/binaries/_ht_1638524593169/content/gallery/play/portal/html/images/components/language-switch/flag-pl.svg"
            class="language-switch__flag" alt="wersja polska"></a></li>
          <li><a href="{{changeLanguage('/en/')}}"><img
            src="https://media-play.pl/binaries/_ht_1638524592548/content/gallery/play/portal/html/images/components/language-switch/flag-gb.svg"
            class="language-switch__flag" alt="english"></a></li>
          <li><a href="{{changeLanguage('/uk/')}}" class="language-switch__link"
                 lang="uk"><img
            src="https://media-play.pl/binaries/_ht_1638524593012/content/gallery/play/portal/html/images/components/language-switch/flag-ua.svg"
            class="language-switch__flag" alt="Українська"></a></li>
        </ul>
      </div>
      <main id="main-container">
        <router-outlet></router-outlet>
        <helper-section></helper-section>
      </main>

      <!--<div [innerHTML]="footer"></div>-->

    </div>
    <captcha-modal></captcha-modal>
  </div>
</div>

<div class="loader-container" *ngIf="!isAllContentLoaded()">
  <img src="https://media-play.pl/binaries/web/external/loader.png" alt="loader"/>
</div>
