import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {NumberValidator} from "./number-validator";

@Directive({
  selector: '[amountValidator]'
})
export class AmountValidatorDirective extends NumberValidator {
  @Input() minAmount = 5;
  @Input() maxAmount = 300;

  readonly MAX_DIGITS = 3;

  constructor(elementRef: ElementRef) { super(elementRef) }

  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    const valStr = String(event.target.value);
    if (Number(event.target.value) && valStr.length > this.MAX_DIGITS) {
      this.valueChangedEvent(event, valStr.substr(0, this.MAX_DIGITS));
    }
  }

  @HostListener('blur', ['$event'])
  onInputBlur(event: any) {
    const value = Number(event.target.value);
    if (!value) {
      this.valueChangedEvent(event, '');
    } else {
      if (value < this.minAmount) {
        this.valueChangedEvent(event, this.minAmount);
      } else if (value > this.maxAmount) {
        this.valueChangedEvent(event, this.maxAmount);
      }
    }
  }

}
