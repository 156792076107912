import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'dynamic-component',
  template: ''
})
export class DynamicComponent implements OnInit, AfterViewInit {

  afterLoadEvent = new EventEmitter();

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) { }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.afterLoadEvent.emit();
  }

  appendChildHtml(htmlSource: HTMLElement) {
    this.renderer.appendChild(this.elementRef.nativeElement, htmlSource);
  }
}
