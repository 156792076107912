import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AppRestService} from "../shared/services/app-rest.service";
import {AuditData, ParamData, RechargesStatusRequest} from "../shared/data/recharges-request-data";
import {CookieDataService, PaymentCookieData} from "../shared/services/cookie-data.service";
import {GiftAmountPipe} from "../shared/pipes/gift-amount.pipe";
import {BLIK_INSTRUMENT_PARAM} from "../recharge/recharge.component";
import {AppStateService} from "../shared/services/app-state.service";
import {GoogleAnalyticsService} from "../shared/services/google-analytics.service";
import {ConfigData} from "../shared/data/config-data";

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html'
})
export class StatusComponent implements OnInit {

  readonly DEFAULT_PAYMENT_MSG = $localize`:@@CantPayment:Nie mogliśmy doładować<br>wskazanego numeru.`;

  paymentStatus = false;
  paymentSuccess = false;
  paymentBlikInstrument = false;
  paymentStatusMessage = '';

  rechargeAmount = 0;
  rechargeGiftAmount = 0;
  rechargeValidation = '';

  statusConfig: ConfigData;
  statusRequestCount = 0;

  constructor(private googleAnalyticsService: GoogleAnalyticsService,
              private rechargesService: AppRestService,
              private cookieService: CookieDataService,
              private stateService: AppStateService,
              private giftPipe: GiftAmountPipe,
              private route: ActivatedRoute,
              private router: Router,
              private restService: AppRestService) { }

  ngOnInit() {
    const params = this.route.snapshot.queryParams;
    this.stateService.configPromise
      .then(config => this.statusConfig = config)
      .catch(() => console.log('Cannot get status config!'));
    this.verifyPageParams();
    let languageStoreCookie = this.cookieService.getLanguageStoreCookie();
    if(languageStoreCookie != null && languageStoreCookie.ua && !this.rechargesService.isUa()){
      this.cookieService.removeLanguageCookie();
      window.location.href = window.location.href.replace("/pl/","/uk/");
    }else if(languageStoreCookie != null && languageStoreCookie.en && !this.rechargesService.isEn()) {
      this.cookieService.removeLanguageCookie();
      window.location.href = window.location.href.replace("/pl/", "/en/");
    }
  }

  private verifyPageParams() {
    this.onQueryParamsChanged();
    this.router.events.subscribe(event => {
      event instanceof NavigationEnd && this.onQueryParamsChanged()
    });
  }

  private onQueryParamsChanged() {
    const params = this.route.snapshot.queryParams;
    if (params['success']) {
      this.showRechargeDetails(params['success'] == 'true', params);
    } else {
      this.fetchRechargeStatus(params);
    }
  }

  private showRechargeDetails(success: boolean, params: any = null) {
    if (success && params) {
      this.rechargeAmount = params['amount'];
      this.rechargeValidation = params['valid'];
      this.rechargeGiftAmount = this.rechargeAmount ? this.giftPipe.transform(this.rechargeAmount) : '';
    }
    const statusMessage = params['msg'];
    if (statusMessage) {
      this.paymentStatusMessage = statusMessage
    } else if (!success) {
      this.paymentStatusMessage = this.DEFAULT_PAYMENT_MSG;
    }
    this.paymentSuccess = success;
    this.paymentBlikInstrument = false;
    this.paymentStatus = true;
  }

  private fetchRechargeStatus(params) {
    this.paymentBlikInstrument = params.hasOwnProperty(BLIK_INSTRUMENT_PARAM);
    const paramsMap = Object.getOwnPropertyNames(params)
      .filter(key => key != BLIK_INSTRUMENT_PARAM)
      .map(key => <ParamData>{ key: key, value: params[key] });

    const paymentCookie = this.cookieService.getPaymentCookie();
    this.getStatusRequest({
      returnUrl: window.location.href.split('?')[0],
      params: paramsMap,
      auditData: StatusComponent.getAuditDataObject(paymentCookie),
      requestId: this.cookieService.getAuditDataCookie().requestId,
      language: "pl"
    }, paymentCookie, this.paymentBlikInstrument);
  }

  private getStatusRequest(rechargesStatus: RechargesStatusRequest, paymentCookie: PaymentCookieData, blikPaymentInstrument: boolean = false) {
    this.rechargesService.getPaymentStatus(rechargesStatus)
      .subscribe(response => {
        this.statusRequestCount++;
        if (response) {
          if (response.status == 'PENDING') {
            if (this.statusRequestCount < this.statusConfig.blik.limit) {
              setTimeout(() => {
                this.getStatusRequest(rechargesStatus, paymentCookie, blikPaymentInstrument);
              }, this.statusConfig.blik.interval);
            } else {
              this.showRechargeStatus(false, paymentCookie, response.paymentId, '');
            }
          } else {
            this.showRechargeStatus(response.status == 'SUCCESS', paymentCookie, response.paymentId);
          }
        }
      },() => this.showRechargeStatus(false, paymentCookie)
    );
  }

  private showRechargeStatus(success: boolean, paymentCookie: PaymentCookieData, paymentId: string = null, message: string = '') {
    let statusParams = { success: success };
    if (success && paymentCookie && paymentId == paymentCookie.paymentId) { // got actual recharge session data
      this.googleAnalyticsService.sendRechargeStatusAnalytics(String(paymentCookie.amount), paymentCookie.instrument);
      Object.assign(statusParams, { amount: paymentCookie.amount, valid: paymentCookie.valid });
    }
    if (message) { Object.assign(statusParams, { msg: message }); }
    this.cookieService.removePaymentCookie();
    this.restService.addVirginBrandToQueryParam(statusParams);
    this.router.navigate(['/statusPage'], { queryParams: statusParams });
  }

  private static getAuditDataObject(cookieData: PaymentCookieData): AuditData {
    const searchParams = new URLSearchParams(window.location.search);
    const isMatch = !!(cookieData && cookieData.paymentId == searchParams.get('paymentId'));
    return {
      match: isMatch,
      referer: document.referrer ? document.referrer : null,
      search: window.location.search,
      cookie: cookieData ? JSON.stringify(cookieData) : null
    }
  }


}
