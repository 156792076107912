import {Injectable} from '@angular/core';
import {AppRestService} from "../services/app-rest.service";
import {CookieDataService} from "../services/cookie-data.service";

declare var ApplePaySession;

@Injectable({ providedIn: 'root' })
export class ApplePayApiService {

  readonly APPLE_PAY_VERSION = 3;
  readonly APPLE_PAY_PAYMENT_LABEL = 'Doładowanie';

  appleSession: any = null;

  constructor(private restService: AppRestService,
              private cookieDataService: CookieDataService) { }

  createNewPayment(amount: number): Promise<string> {
    this.cookieDataService.setLanguageStoreCookie(this.restService.isUa(),this.restService.isVirgin(),this.restService.isEn());
    return new Promise((resolve, reject) => {
      // amount = 0.0; // TODO for testing
      this.appleSession = new ApplePaySession(this.APPLE_PAY_VERSION, this.getPaymentRequest(amount));

      this.appleSession.onvalidatemerchant = (event) => {
        this.restService.postCreateApplePaySession(event.validationURL)
          .subscribe(
            (response) => { this.appleSession.completeMerchantValidation(response); },
            () => { this.appleSession.abort(); reject(null); }
            );
      };

      this.appleSession.onpaymentauthorized = (event) => {
        try {
          const paymentData = event.payment.token.paymentData;
          resolve(btoa(JSON.stringify(paymentData)));
        } catch (e) {
          this.finalizePayment(false);
          reject(event);
        }
      };

      // cancel
      this.appleSession.oncancel = (event) => {
        console.log(['oncancel', event]);
        reject(event);
      };

      this.appleSession.begin();
    });
  }

  finalizePayment(success: boolean) {
    this.appleSession && this.appleSession.completePayment(
      success ? ApplePaySession.STATUS_SUCCESS : ApplePaySession.STATUS_FAILURE
    );
  }

  private getPaymentRequest(amount: number) {
    return {
      countryCode: 'PL', currencyCode: 'PLN',
      total: {
        label: this.APPLE_PAY_PAYMENT_LABEL,
        amount: amount,
      },
      supportedNetworks: ['visa', 'masterCard'],
      merchantCapabilities: ['supports3DS'],
    };
  }
}
