<div class="payment-bank-transfer" style="display: block !important">
  <h3 class="text-left half-base-margin-bottom"
      [ngStyle]="{ color : instrumentInvalid ? ' #d03737' : '' }"  i18n="choiceBank">Wybierz bank:</h3>
  <ol class="banks-list">
    <li *ngFor="let bankPayment of banksOptions; let i = index">
      <input class="payment-transfer" [ngClass]="'bank-transfer-' + bankPayment.code"
             [id]="'bank-transfer-' + i" type="radio" focusable
             name="bank-transfer" (click)="selectBank(bankPayment)" [attr.disabled]="bankPayment.status !='ACTIVE' ? '' : null">
      <label class="" [for]="'bank-transfer-' + i">
        <div class="svg-wrp">
          <img style="padding: .5rem" [src]="bankPayment.iconUrl"
               [alt]="bankPayment.name" [ngStyle]="{'filter': bankPayment.status == 'ACTIVE' ? 'grayscale(0%)' : 'grayscale(100%)' }" style="max-width: 100% !important;">
        </div>
        <span class="show-for-small-only text-left">{{ bankPayment.name }}</span>
        <span class="show-for-small-only icon icon-check"></span>
      </label>
    </li>
  </ol>
  <small *ngIf="false" role="alert" class="error-msg-text" i18n="noChoiseBank">Bank nie został wybrany</small>
</div>
