import {AfterViewInit, Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[buttonResizer]'
})
export class ButtonResizerDirective implements AfterViewInit {

  appleBtnElement: HTMLElement;
  googleBtnElement: HTMLElement;
  appleBtnLastSize: [number, number] = [0, 0];
  googleBtnLastSize: [number, number] = [0, 0];

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.appleBtnElement = this.findPaymentBtn('apple-pay-btn');
    this.updateButtonSizeToPattern(this.appleBtnElement, this.appleBtnLastSize);
  }

  @HostListener('btnInit')
  onPaymentBtnInit() {
    this.googleBtnElement = this.findPaymentBtn('gpay-button');
    this.updateButtonSizeToPattern(this.googleBtnElement, this.googleBtnLastSize);
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.updateButtonSizeToPattern(this.appleBtnElement, this.appleBtnLastSize);
    this.updateButtonSizeToPattern(this.googleBtnElement, this.googleBtnLastSize);
  }

  private updateButtonSizeToPattern(paymentBtnElement: HTMLElement, paymentBtnLastSize: [number, number]) {
    if (paymentBtnElement) {
      this.doWithPatternElementSize((width, height) => {
        if (paymentBtnLastSize[0] != width || paymentBtnLastSize[1] != height) {
          paymentBtnLastSize = [width, height];
          this.setButtonSize(paymentBtnElement, paymentBtnLastSize);
        }
      });
    }
  }

  private setButtonSize(paymentBtnElement: HTMLElement, paymentBtnLastSize: [number, number]) {
    this.renderer.setStyle(paymentBtnElement, 'width', paymentBtnLastSize[0]);
    this.renderer.setStyle(paymentBtnElement, 'height', paymentBtnLastSize[1]);
  }

  private findPaymentBtn(className: string): HTMLElement {
    return Array.from<HTMLButtonElement>(this.elementRef.nativeElement.children)
      .find((element: HTMLButtonElement) => element.classList.contains(className));
  }

  private doWithPatternElementSize(callback: (width: number, height: number) => void) {
    Array.from(this.elementRef.nativeElement.children)
      .filter((element: HTMLElement) => element.id == 'step-back')
      .forEach((element: HTMLElement) => {
        const computedStyle = getComputedStyle(element);
        callback.call(this, computedStyle['width'], computedStyle['height'])
      });
  }

}
