import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {FormUtility} from "../shared/utility/form-utility";
import {RechargesPaymentRequest} from "../shared/data/recharges-request-data";
import {CookieDataService} from "../shared/services/cookie-data.service";
import {AppRestService} from "../shared/services/app-rest.service";
import {Router} from "@angular/router";
import {RechargeData} from "./amount-section/amount-section.component";
import {AppStateService} from "../shared/services/app-state.service";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ApplePayApiService} from "../shared/api/apple-pay-api.service";
import {RECHARGE_STATE} from "../shared/utility/recharge.state";

export const INSTRUMENT_CODES = {
  TRANSFER: 'TRANSFER',
  GOOGLE_PAY: 'GPAY',
  APPLE_PAY: 'APAY',
  CARD: 'CARD',
  BLIK: 'BLIK'
};

export const BLIK_INSTRUMENT_PARAM = 'blikInstrument';

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html'
})
export class RechargeComponent implements OnInit, OnDestroy {

  readonly RECHARGE_STATE = RECHARGE_STATE;

  isConfigLoaded = false;
  isClientDataStored = false;
  currentRechargeState = RECHARGE_STATE.AMOUNT;

  amountFormGroup: UntypedFormGroup;
  paymentFormGroup: UntypedFormGroup;

  paymentRequest = new RechargesPaymentRequest();
  paymentValidTo = '';

  constructor(private router: Router,
              private elementRef: ElementRef,
              private cookieService: CookieDataService,
              private stateService: AppStateService,
              private applePayService: ApplePayApiService,
              private rechargesService: AppRestService,
              public restService: AppRestService) { }

  ngOnInit() {
    this.stateService.configPromise
      .then(() => this.setupForms())
      .catch(() => this.gotoStatusPage({ success: false, msg: 'Nieprawidłowa konfiguracja.' }));
    this.stateService.changeState(this.currentRechargeState);
  }

  private setupForms() {
    // amount section
    this.amountFormGroup = new UntypedFormGroup({
      msisdn: new UntypedFormControl('', [Validators.required, FormUtility.validateMsisdn],
        FormUtility.validateMsisdnStatus.bind(this, this.rechargesService)),
      amount: new UntypedFormControl(0, [Validators.required,
        FormUtility.validateAmount(this.stateService.configData.amount.min, this.stateService.configData.amount.max)]),
      validTo: new UntypedFormControl('')
    });
    this.stateService.setupAmountForm(this.amountFormGroup);
    // payment section
    this.paymentFormGroup = new UntypedFormGroup({
      instrument: new UntypedFormControl(null, [FormUtility.validateInstrument]),
      email: new UntypedFormControl('', [Validators.required, FormUtility.validateEmail]),
      agreement: new UntypedFormControl(false, [Validators.requiredTrue]),
      authCode: new UntypedFormControl(''),
      store: new UntypedFormControl(false)
    });
    this.stateService.setupPaymentForm(this.paymentFormGroup);
    this.isConfigLoaded = true;
  }

  ngOnDestroy(): void {
    FormUtility.removeFormControls(this.amountFormGroup);
    FormUtility.removeFormControls(this.paymentFormGroup);
  }

  // collect data

  onRechargeDataCollected(rechargeData: RechargeData) {
    this.paymentRequest.rechargeMsisdn = FormUtility.getMsisdn(rechargeData.form.msisdn);
    this.paymentRequest.amount = rechargeData.form.amount;
    this.paymentValidTo = rechargeData.valid;
    this.changeAppState(RECHARGE_STATE.PAYMENT);
  }

  onPaymentDataCollected(formData: any) {
    if (!formData) { // return if NULL
      this.changeAppState(RECHARGE_STATE.AMOUNT);
    } else { // collect data
      this.isClientDataStored = formData.store;
      this.paymentRequest.email = formData.email;
      this.paymentRequest.instrumentCode = formData.instrument;
      this.processPaymentData(formData);
      this.elementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onBlikDataCollected(blikCode: string) {
    if (!blikCode) { // return if NULL
      this.changeAppState(RECHARGE_STATE.PAYMENT);
    } else { // collect data
      this.paymentRequest.authorizationCode = blikCode;
      this.createNewPayment();
    }
  }

  private changeAppState(state: RECHARGE_STATE) {
    this.currentRechargeState = state;
    this.stateService.changeState(this.currentRechargeState);
  }

  private processPaymentData(formData: any) {
    if (this.paymentRequest.instrumentCode != INSTRUMENT_CODES.BLIK) { // process payment
      this.setAuthorizationCode(formData);
      this.createNewPayment();
    } else {
      this.changeAppState(RECHARGE_STATE.BLIK);
    }
  }

  private setAuthorizationCode(formData: any) {
    if (this.paymentRequest.instrumentCode == INSTRUMENT_CODES.GOOGLE_PAY ||
      this.paymentRequest.instrumentCode == INSTRUMENT_CODES.APPLE_PAY) {
      this.paymentRequest.authorizationCode = formData.authCode;
    }
  }

  // new payment

  private redirectClient(returnUrl: string) {
    if (this.paymentRequest.instrumentCode == INSTRUMENT_CODES.BLIK)
      returnUrl += '&' + BLIK_INSTRUMENT_PARAM;
    window.location.href = returnUrl;
  }

  private createNewPayment() {
    this.rechargesService.postNewPayment(this.paymentRequest)
      .subscribe(response => {
          this.finishPaymentMethod(true);
          if (response.url) {
            this.updateCookies(this.isClientDataStored, response.paymentId);
            this.redirectClient(response.url);
          } else {
            this.gotoStatusPage({ success: false })
          }
        },
        () => {
          this.finishPaymentMethod(false);
          this.gotoStatusPage({ success: false });
        }
      );
  }

  private finishPaymentMethod(success: boolean) {
    if (this.paymentRequest.instrumentCode == INSTRUMENT_CODES.APPLE_PAY) {
      this.applePayService.finalizePayment(success);
    }
  }

  private updateCookies(storeClientData: boolean, paymentId: string) {
    if (storeClientData) { this.cookieService.storeFormData(this.paymentRequest);
    } else { this.cookieService.removeFormCookie(); }
    this.cookieService.storePaymentCookie(paymentId, this.paymentRequest.amount, this.paymentRequest.instrumentCode, this.paymentValidTo);
  }

  private gotoStatusPage(params: any) {
    this.restService.addVirginBrandToQueryParam(params);
    this.router.navigate(['/statusPage'], { queryParams: params});
  }

  public getMainTitle(){
    if(this.currentRechargeState == RECHARGE_STATE.AMOUNT){
      return $localize`:@@mainTitleAdd:Doładowania`;
    }else{
      return $localize`:@@mainTitlePay:Metoda płatności`;
    }
  }

}
