import {Injectable} from '@angular/core';
import {CookieOptions, CookieService} from "ngx-cookie-service";
import {FormUtility} from "../utility/form-utility";
import {UntypedFormGroup} from "@angular/forms";
import {RechargesPaymentRequest} from "../data/recharges-request-data";

@Injectable({
  providedIn: 'root'
})
export class CookieDataService {

  readonly CHECK_MSISDN_COOKIE_EXPIRE_TIME = 1;
  readonly CHECK_MSISDN_COOKIE_ATTEMPT_THRESHOLD = 5;

  readonly FORM_COOKIE_NAME = 'recharges.client.data';
  readonly PAYMENT_COOKIE_NAME = 'recharges.payment.data';
  readonly CACHE_COOKIE_NAME = 'recharges.cache.date';
  readonly CHECK_MSISDN_COOKIE_NAME = 'recharges.msisdn.data';
  readonly LANGUAGE_COOKIE_NAME = 'language.cookie.name';
  readonly AUDIT_COOKIE_NAME = 'audit.cookie.name';

  constructor(private cookieService: CookieService) { }

  // Msidn Cookie

  verifyCheckMsisdnAttemptValid() {
    const checkMsidnAttempt = Number(this.cookieService.get(this.CHECK_MSISDN_COOKIE_NAME));
    if (checkMsidnAttempt) {
      return checkMsidnAttempt < this.CHECK_MSISDN_COOKIE_ATTEMPT_THRESHOLD;
    }
    const expireDate = new Date();
    expireDate.setHours(expireDate.getHours() + this.CHECK_MSISDN_COOKIE_EXPIRE_TIME);
    this.cookieService.set(this.CHECK_MSISDN_COOKIE_NAME, '0', {
      expires: expireDate
    });
    return true;
  }

  // Form Cookie

  storeFormData(paymentRequest: RechargesPaymentRequest): void {
    let msisdn = FormUtility.getMsisdn(paymentRequest.rechargeMsisdn);
    if (msisdn.length > 9) msisdn = msisdn.substr(msisdn.length - 9);

    const formData = JSON.stringify({
      msisdn: msisdn,
      amount: paymentRequest.amount,
      instrument: paymentRequest.instrumentCode,
      email: paymentRequest.email
    });

    this.cookieService.set(
      this.FORM_COOKIE_NAME,
      formData,
      CookieDataService.getFormCookieOptions()
    );
  }

  restoreAmountForm(formGroup: UntypedFormGroup): boolean {
    const userDataCookie = this.getFormCookie();
    if (!userDataCookie) return false;
    formGroup.get('msisdn').setValue(userDataCookie['msisdn']);
    formGroup.get('amount').setValue(userDataCookie['amount']);
    return true;
  }

  restorePaymentForm(formGroup: UntypedFormGroup): boolean {
    const userDataCookie = this.getFormCookie();
    if (!userDataCookie) return false;
    formGroup.get('instrument').setValue(userDataCookie['instrument']);
    formGroup.get('email').setValue(userDataCookie['email']);
    formGroup.get('store').setValue(true);
    return true;
  }

  removeLanguageCookie() {
    this.cookieService.delete(this.LANGUAGE_COOKIE_NAME);
  }

  removeFormCookie() {
    this.cookieService.delete(this.FORM_COOKIE_NAME);
  }

  private getFormCookie(): any {
    const cookieValue = this.cookieService.get(this.FORM_COOKIE_NAME);
    return cookieValue ? JSON.parse(cookieValue) : null;
  }

  // Payment Cookie


  storePaymentCookie(paymentId: string, amount: number, instrumentCode: string, valid: string): void {
    const paymentData = JSON.stringify({
      paymentId: paymentId,
      amount: amount,
      instrument: instrumentCode,
      valid: valid
    });

    this.cookieService.set(
      this.PAYMENT_COOKIE_NAME,
      paymentData
    );
  }

  removePaymentCookie() {
    this.cookieService.delete(this.PAYMENT_COOKIE_NAME);
  }

  getPaymentCookie(): PaymentCookieData | null {
    const cookieValue = this.cookieService.get(this.PAYMENT_COOKIE_NAME);
    return cookieValue ? JSON.parse(cookieValue) as PaymentCookieData : null;
  }

  // Cache cookie (remote page components)

  getComponentsCacheCookieOrCreate(expireTimeInHours: number): String {
    const cacheCookie = this.cookieService.get(this.CACHE_COOKIE_NAME);
    if (!!cacheCookie) return cacheCookie;
    const expireDate = new Date();
    expireDate.setHours(expireDate.getHours() + expireTimeInHours);
    this.cookieService.set(this.CACHE_COOKIE_NAME, 'cache', {
      expires: expireDate
    });
    return null;
  }

  // Cookie Options

  private static getFormCookieOptions(): CookieOptions {
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 2);
    let month = currentDate.getMonth() + 1;
    let monthValue = month > 9 ? month : '0' + month;
    return { expires: new Date(currentDate.getFullYear() + 1 + '-' + monthValue + '-' + currentDate.getDate()) };
  }
  public setLanguageStoreCookie(ua: boolean, virgin: boolean,en: boolean) {
    const languageData = JSON.stringify({
      ua: ua,
      virgin: virgin,
      en: en
    });

    this.cookieService.set(
      this.LANGUAGE_COOKIE_NAME,
      languageData,
    );
  }
  public setAuditDataCookie(requestId: string) {
    const auditData = JSON.stringify({
      requestId: requestId
    });

    this.cookieService.set(
      this.AUDIT_COOKIE_NAME,
      auditData,
    );
  }
  public getAuditDataCookie(): AuditDataCookie | null {
    const cookieValue = this.cookieService.get(this.AUDIT_COOKIE_NAME);
    return cookieValue ? JSON.parse(cookieValue) as AuditDataCookie : null;
  }
  public getLanguageStoreCookie(): LanguageStoreCookie | null {
    const cookieValue = this.cookieService.get(this.LANGUAGE_COOKIE_NAME);
    return cookieValue ? JSON.parse(cookieValue) as LanguageStoreCookie : null;
  }

}
export class LanguageStoreCookie {
  ua: boolean;
  virgin: boolean;
  en: boolean;

  constructor(ua: boolean = false, virgin: boolean = false, en: boolean = false) {
    this.ua = ua;
    this.virgin = virgin;
    this.en = en;
  }
}
export class AuditDataCookie {
  requestId: string;

  constructor(requestId: string) {
    this.requestId = requestId;
  }
}

export class PaymentCookieData {
  paymentId: string;
  amount: number;
  instrument: string;
  valid: string;

  constructor(paymentId: string, amount: number, instrument: string, valid: string) {
    this.paymentId = paymentId;
    this.amount = amount;
    this.instrument = instrument;
    this.valid = valid;
  }
}
