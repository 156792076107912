import {PlayPaymentMethodData} from "../data/recharges-response-data";
import {INSTRUMENT_CODES} from "../../recharge/recharge.component";

export class PaymentMethodsUtility {

  static filterPaymentMethods(paymentMethods: PlayPaymentMethodData[]) {
    return paymentMethods.filter(paymentMethod =>
      this.verifyPaymentMethodStatusPredicate(paymentMethod) &&
      this.checkGooglePayStatusPredicate(paymentMethod) &&
      this.verifyApplePayStatusPredicate(paymentMethod)
    );
  }

  // predicates

  private static verifyPaymentMethodStatusPredicate(paymentMethod: PlayPaymentMethodData): boolean {
    if (!paymentMethod.paymentOptions || paymentMethod.paymentOptions.length == 0) { return false; }
    return !!paymentMethod.paymentOptions
      .find(paymentOption => paymentOption.status == 'ACTIVE')
  }

  private static checkGooglePayStatusPredicate(paymentMethod: PlayPaymentMethodData): boolean {
    if (paymentMethod.type != INSTRUMENT_CODES.GOOGLE_PAY) return true;
    return PaymentMethodsUtility.isGooglePaySupported();
  }

  private static verifyApplePayStatusPredicate(paymentMethod: PlayPaymentMethodData): boolean {
    if (paymentMethod.type != INSTRUMENT_CODES.APPLE_PAY) return true;
    return PaymentMethodsUtility.isApplePaySupported();
  }

  // utils

  static getActivePaymentInstrument(paymentMethods: PlayPaymentMethodData[], instrumentCode: string) {
    return paymentMethods.find(paymentMethod =>
      paymentMethod.type == instrumentCode && this.verifyPaymentMethodStatusPredicate(paymentMethod));
  }

  static isApplePaySupported() {
    return window.hasOwnProperty('ApplePaySession') && window['ApplePaySession'].canMakePayments;
  }

  static isGooglePaySupported() {
    return window.hasOwnProperty('google');
  }

}
