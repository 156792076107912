import {Injectable} from "@angular/core";
import {AppStateService} from "../services/app-state.service";
import {PlayPaymentMethodData} from "../data/recharges-response-data";
import {PaymentMethodsUtility} from "../utility/payment-methods-utility";
import {AppRestService} from "../services/app-rest.service";
import {CookieDataService} from "../services/cookie-data.service";

declare var google;

@Injectable({ providedIn: 'root' })
export class GooglePayApiService {

  private readonly googlePayBaseRequest = { apiVersion: 2, apiVersionMinor: 0 };

  private paymentsClient;
  private paymentMerchantId: string = '';
  private paymentMerchantGateway: string = '';
  private paymentMerchantName: string = '';

  constructor(private appState: AppStateService,
              private appRestService: AppRestService,
              private cookieDataService: CookieDataService) {
    if (PaymentMethodsUtility.isGooglePaySupported()) {
      this.cookieDataService.setLanguageStoreCookie(this.appRestService.isUa(),this.appRestService.isVirgin(),this.appRestService.isEn());
      this.paymentMerchantName = "P4";
      this.paymentsClient = new google.payments.api.PaymentsClient({ environment: appRestService.config.release ? 'PRODUCTION' : 'TEST' });
    }
  }

  // button

  initPaymentButton(context: any, merchant: string, btnCallback: Function, btnLong: boolean = false, btnWhite: boolean = false): Promise<HTMLButtonElement> {
    return new Promise((resolve, reject) => {
      if (!this.paymentsClient) reject('[GooglePay] paymentsClient is not initialized');
      if (!merchant) reject('[GooglePay] Merchant ID is empty');
      this.setupMerchantData(merchant);
      this.paymentsClient.isReadyToPay(this.googleIsReadyRequest)
        .then((response) => {
          if (response.result) {
            resolve(this.paymentsClient.createButton({
              onClick: (event) => {
                event.preventDefault();
                btnCallback.call(context, event);
              },
              buttonType: btnLong ? 'long' : 'short',
              buttonColor: btnWhite? 'white' : 'black'
            }).firstChild);
          }
          reject(null);
        })
        .catch((err) => reject(err));
    });
  }

  private setupMerchantData(merchant: string) {
    const merchantData = merchant.split('@');
    this.paymentMerchantId = merchantData[0];
    this.paymentMerchantGateway = (merchantData.length > 1) ? merchantData[1] : 'payu';
  }

  createNewPayment(totalPrice: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const paymentDataRequest = this.getGooglePaymentDataRequest(totalPrice);
      this.paymentsClient.loadPaymentData(paymentDataRequest)
        .then((paymentData: PaymentDataResponse) => {
          const token = paymentData.paymentMethodData.tokenizationData.token;
          if (token) resolve(btoa(token));
          reject(paymentData);
        })
        .catch((err) => { reject(err); }); // console.error(err);
    });
  };

  private getGooglePaymentDataRequest(totalPrice: string) {
    let paymentDataRequest: PaymentDataRequest = Object.assign({}, new PaymentDataRequest, this.googlePayBaseRequest);
    paymentDataRequest.allowedPaymentMethods = [ this.getCardPaymentMethod() ];
    paymentDataRequest.transactionInfo = GooglePayApiService.getGoogleTransactionInfo(totalPrice);
    paymentDataRequest.merchantInfo = {
      merchantName: this.paymentMerchantName,
      merchantId: '15586512722890050191' // TODO config from backend
    };
    return paymentDataRequest;
  }

  private static getGoogleTransactionInfo(totalPrice: string) {
    return { currencyCode: 'PLN', totalPriceStatus: 'FINAL', totalPrice: totalPrice, checkoutOption: 'DEFAULT'};
  }

  // setup

  private readonly googlePayCardPaymentMethod = {
    type: 'CARD', parameters: {
      allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
      allowedCardNetworks: ["MASTERCARD", "VISA"]
    }
  };

  private readonly googleIsReadyRequest = Object.assign({}, this.googlePayBaseRequest,
    { allowedPaymentMethods: [ this.googlePayCardPaymentMethod ] }
  );

  // payment

  private getCardPaymentMethod() {
    return Object.assign({}, this.googlePayCardPaymentMethod,
      { tokenizationSpecification: this.getTokenizationSpecification() });
  }

  private getTokenizationSpecification() {
    return { type: 'PAYMENT_GATEWAY', parameters: {
      'gateway': this.paymentMerchantGateway, 'gatewayMerchantId': this.paymentMerchantId // '341563'
    }}
  }

  // api

  isApiReady(): boolean {
    return !!this.paymentsClient;
  }

  static getPaymentMerchantId(paymentMethodData: PlayPaymentMethodData): string {
    if (paymentMethodData == null || paymentMethodData.paymentOptions.length < 1) return null;
    const merchantParam = paymentMethodData.paymentOptions[0].customParams
      .find(param => param.key == 'GPAY_MERCHANT_ID');
    if (!merchantParam) return null;
    return merchantParam.value;
  }
}

export class PaymentDataRequest {
  allowedPaymentMethods: any[];
  transactionInfo: any;
  merchantInfo: any;
}

export interface PaymentDataResponse {
  apiVersion: number;
  apiVersionMinor: number;
  paymentMethodData: PaymentMethodData;
}

export interface PaymentMethodData {
  description: string;
  info: CardInfo;
  tokenizationData: TokenizationData;
  type: string;
}

export interface CardInfo {
  cardDetails: string;
  cardNetwork: string;
}

export interface TokenizationData {
  token: string;
  type: string;
}
