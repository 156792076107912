import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {FormInputUtility} from "../../shared/utility/form-input-utility";
import {FormUtility} from "../../shared/utility/form-utility";
import {AppRestService} from "../../shared/services/app-rest.service";

import Inputmask from "inputmask/dist/inputmask/inputmask.date.extensions.js";
import {GoogleAnalyticsService} from "../../shared/services/google-analytics.service";

@Component({
  selector: 'amount-section',
  templateUrl: './amount-section.component.html'
})
export class AmountSectionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() submitEvent = new EventEmitter<RechargeData>();
  @Input() formGroup: UntypedFormGroup;

  @ViewChild('msisdnInput', { static: true }) msisdnInput: ElementRef;

  readonly VALID_DATE_FORMAT = { day: 'numeric', month: 'long', year: 'numeric' }  as const;
  readonly MSISDN_MASK = '+48 999 999 999';
  readonly AMOUNT_TAB_VOICE = $localize`:@@AmountTabVoice:telefon`;
  readonly AMOUNT_TAB_INTERNET = $localize`:@@AmountTabInternet:internet`;
  readonly AMOUNT_TAB_TITLES_LIST = [this.AMOUNT_TAB_VOICE, this.AMOUNT_TAB_INTERNET];

  selectedTab = this.AMOUNT_TAB_TITLES_LIST[0];
  validDate = '';

  constructor(public rechargesService: AppRestService,
              private googleAnalyticsService: GoogleAnalyticsService,
              public  restService: AppRestService) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    Inputmask({mask: this.MSISDN_MASK, showMaskOnHover: false})
      .mask(this.msisdnInput.nativeElement);
  }

  private updateValidDateValue(validTo: string) {
    this.validDate = validTo ? new Date(validTo)
      .toLocaleDateString("pl-PL", this.VALID_DATE_FORMAT) : '';
  }

  private fetchRechargeValidDate() {
    const msisdn = FormUtility.getMsisdn(this.formGroup.get('msisdn').value);
    const amount = this.formGroup.get('amount').value;
        this.rechargesService.getRechargesValidDate({
          amount: amount,
          msisdn: msisdn,
        }).subscribe(response => {
          this.updateValidDateValue(response.validTo);
        }, response => {
          this.updateValidDateValue('');
        });
      }
  onFormSubmit() {
    FormUtility.markFormAsTouched(this.formGroup);
    if (this.formGroup.valid) {
      const isVoice = this.selectedTab == this.AMOUNT_TAB_VOICE;
      this.googleAnalyticsService.sendAmountSelectionAnalytics(this.formGroup.value.amount, isVoice);
      this.submitEvent.emit({ form: this.formGroup.value, valid: this.validDate });
    } else {
      console.error(this.formGroup.controls);
    }
  }

  isMsisdnInputInvalid() {
    return FormInputUtility.isInputInvalid(this.formGroup.get('msisdn'));
  }

  getMsisdnErrorMsg() {
    let err = FormInputUtility.getErrorMsg(this.formGroup.get('msisdn'), FormInputUtility.MSISDN_ERROR_MSG);
    if(this.formGroup.get('msisdn').getError("notBelongsToP4")){
      if(this.restService.isVirgin()) {
        err = err + " Virgin Mobile";
      }else{
        err = err + " Play";
      }
    }
    return err;
  }
  isPl(){
    if(this.restService.isEn() || this.restService.isUa()){
      return false;
    }else{
      return true;
    }
  }
}

export interface RechargeData {
  form: any; valid: string;
}
