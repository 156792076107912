import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {PaymentOptionsData, PlayPaymentMethodData} from "../../../shared/data/recharges-response-data";
import {UntypedFormGroup} from "@angular/forms";
import {INSTRUMENT_CODES} from "../../recharge.component";

@Component({
  selector: 'payment-methods',
  templateUrl: './payment-methods.component.html'
})
export class PaymentMethodsComponent implements OnInit, AfterViewInit {
  @Output() selectEvent = new EventEmitter<PaymentOptionsData>();
  @Input() paymentInstrumentInvalid = false;
  @Input() paymentInitialType = '';
  @Input() paymentForm: UntypedFormGroup;
  @Input() paymentAmount = 0;
  @Input() paymentMethodsData: PlayPaymentMethodData[] = [];

  readonly PAYMENT_INSTRUMENTS = INSTRUMENT_CODES;

  selectedPaymentMethod: PlayPaymentMethodData;

  constructor(private elementRef: ElementRef,
              private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.paymentInitialType) this.restorePaymentMethod();
  }

  private restorePaymentMethod() {
    if (!this.paymentMethodsData) return;
    const paymentMethod = this.paymentMethodsData
      .find(paymentMethod => paymentMethod.type == this.paymentInitialType);
    if (paymentMethod) {
      this.selectPaymentMethodTile(this.paymentInitialType);
    } else {
      const transferMethod = this.paymentMethodsData
        .find(paymentMethod => paymentMethod.type == INSTRUMENT_CODES.TRANSFER);
      if (transferMethod) {
        this.selectPaymentMethodTile(INSTRUMENT_CODES.TRANSFER);
        if (transferMethod.paymentOptions
          .find(paymentMethod => paymentMethod.code == this.paymentInitialType)) {
          this.selectBankTile(this.paymentInitialType);
        }
      }
    }
  }

  onPaymentMethodSelected(paymentMethod: PlayPaymentMethodData) {
    this.selectedPaymentMethod = paymentMethod;
    if (paymentMethod.type != INSTRUMENT_CODES.TRANSFER && paymentMethod.paymentOptions.length == 1) {
      this.selectEvent.emit(paymentMethod.paymentOptions[0]);
    }else if((paymentMethod.type == "CARD" || paymentMethod.type == "BLIK") && paymentMethod.paymentOptions.length != 0){
      paymentMethod.paymentOptions.forEach(
        element => {
          if(element.code == "CARD" || element.code == "BLIK"){
            this.selectEvent.emit(element);
          }
        }
      );
    } else {
      this.selectEvent.emit(null);
    }
  }

  onBankSelected(bankData: PaymentOptionsData) {
    if (this.selectedPaymentMethod.type == INSTRUMENT_CODES.TRANSFER) {
      this.selectEvent.emit(bankData);
    }
  }

  getPaymentImageSrc(paymentMethod: PlayPaymentMethodData) {
    switch (paymentMethod.type) {
      case INSTRUMENT_CODES.CARD: return 'assets/images/payment_card.svg';
      case INSTRUMENT_CODES.TRANSFER: return 'assets/images/payment_online.svg';
      case INSTRUMENT_CODES.BLIK: return 'assets/images/payment_blik.png';
      case INSTRUMENT_CODES.GOOGLE_PAY: return 'assets/images/payment_google-pay.svg';
      case INSTRUMENT_CODES.APPLE_PAY: return 'assets/images/payment_apple-pay.svg';
    }
    if (paymentMethod.paymentOptions[0])
      return paymentMethod.paymentOptions[0].iconUrl;
    return '';
  }

  private selectPaymentMethodTile(type) {
    const paymentsElements = this.elementRef.nativeElement.getElementsByClassName('payment-method-' + type);
    if (paymentsElements.length > 0) paymentsElements[0].click();
    this.changeDetector.detectChanges();
  }

  private selectBankTile(code) {
    const banksElements = this.elementRef.nativeElement.getElementsByClassName('bank-transfer-' + code);
    if (banksElements.length > 0) banksElements[0].click();
    this.changeDetector.detectChanges();
  }

  public getPaymentMethodWithLocalize(paymentMethod: PlayPaymentMethodData) {
    switch (paymentMethod.type) {
      case INSTRUMENT_CODES.CARD: {
          return  $localize`:@@cardPayment:Płatność kartą`;
      }
      case INSTRUMENT_CODES.TRANSFER: {
          return  $localize`:@@bankPayment:Przelew bankowy`;
      }
      default: return paymentMethod.displayName
    }
  }
}
