
export class RechargesValidRequest {
  amount: number;
  msisdn: string;
}

export class RechargesPaymentRequest {
  rechargeMsisdn: string;
  amount: number;
  email: string;
  instrumentCode: string;
  authorizationCode: string = '';
  branding: string;
  requestId: string;
  language: string;
}

export interface RechargesStatusRequest {
  returnUrl: string;
  params: ParamData[];
  auditData: AuditData;
  requestId: string;
  language: string;
}

export interface AuditData {
  match: boolean;
  referer: string;
  search: string;
  cookie: string;
}

export interface CheckMsisdnRequest {
  msisdn: string;
}

export class ParamData {
  key: string; value: string;
}
