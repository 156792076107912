export class Config{
  rechargesBackendUrl : string ='';
  release : boolean;
  playHeaderUrl: string='';
  playFooterUrl: string='';
  virginDomain: string='';
  playDomain: string='';
  playCookiesScriptCode: string='';
  virginCookieScriptCode: string='';
}
