import {EventEmitter, Injectable} from '@angular/core';
import {RechargesPaymentRequest, RechargesStatusRequest, RechargesValidRequest} from "../data/recharges-request-data";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {
  AppSettings,
  CheckMsisdnResponse,
  RechargesNewPaymentResponse,
  RechargesPaymentMethodsRequest,
  RechargesPaymentMethodsResponse,
  RechargesStatusResponse,
  RechargesValidResponse
} from "../data/recharges-response-data";
import {Observable} from "rxjs";
import {Config} from "../data/config";
import {environment} from "../../../environments/environment";
import {CookieDataService} from "./cookie-data.service";

@Injectable()
export class AppRestService {

  readonly APP_VERSION_HEADER_NAME = 'AppVersion';
  readonly APP_VERSION_HEADER_LITE_VALUE = 'LITE';

  readonly ENDPOINT_BILLING_STATUS = 'recharges/rechargesStatus';
  readonly ENDPOINT_RECHARGES_METHODS = 'recharges/paymentMethods';
  readonly ENDPOINT_RECHARGES_PAYMENT = 'recharges/newPayment';
  readonly ENDPOINT_RECHARGES_STATUS = 'recharges/status';
  readonly ENDPOINT_FIND_OPERATOR_CHECK = 'recharges/check';

  public msisdnStatusCache: MsisdnStatusCache;

  private commonHeaders = new HttpHeaders();

  public config: Config = new Config();

  public isActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private httpClient: HttpClient,
              private cookieDataService: CookieDataService) {
    this.config = this.getConfig();
  }

  setLiteVersion() {
    this.commonHeaders = this.commonHeaders.set(this.APP_VERSION_HEADER_NAME, this.APP_VERSION_HEADER_LITE_VALUE);
  }

  // Billing

  getRechargesValidDate(rechargesValidRequest: RechargesValidRequest): Observable<RechargesValidResponse> {
    return this.httpClient.post<RechargesValidResponse>(
      this.config.rechargesBackendUrl + this.ENDPOINT_BILLING_STATUS, rechargesValidRequest, {headers: this.commonHeaders}
    )
  }

  // Payment

  getPaymentMethods(rechargesPaymentMethodsRequest: RechargesPaymentMethodsRequest): Observable<RechargesPaymentMethodsResponse> {
    rechargesPaymentMethodsRequest.language = this.getLanguage();
    return this.httpClient.post<RechargesPaymentMethodsResponse>(
      this.config.rechargesBackendUrl + this.ENDPOINT_RECHARGES_METHODS,rechargesPaymentMethodsRequest, {headers: this.commonHeaders}
    )
  }

  postNewPayment(rechargesPaymentRequest: RechargesPaymentRequest) {
    this.cookieDataService.setLanguageStoreCookie(this.isUa(), this.isVirgin(),this.isEn());
    this.cookieDataService.setAuditDataCookie(this.makeid(20));
    rechargesPaymentRequest.language = this.getLanguage();
    if(this.isVirgin()){
      rechargesPaymentRequest.branding = AppSettings.BRAND_VIRGIN;
    }else{
      rechargesPaymentRequest.branding = AppSettings.BRAND_PLAY;
    }
    return this.httpClient.post<RechargesNewPaymentResponse>(
      this.config.rechargesBackendUrl + this.ENDPOINT_RECHARGES_PAYMENT, rechargesPaymentRequest, {headers: this.commonHeaders}
    );
  }

  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  getPaymentStatus(rechargesStatus: RechargesStatusRequest) {
    rechargesStatus.requestId = this.cookieDataService.getAuditDataCookie().requestId;
    rechargesStatus.language = this.getLanguage();
    return this.httpClient.post<RechargesStatusResponse>(
      this.config.rechargesBackendUrl + this.ENDPOINT_RECHARGES_STATUS, rechargesStatus, {headers: this.commonHeaders}
    )
  }

  // Msisdn

  getMsisdnStatus(rechargesValidRequest: RechargesValidRequest): Observable<CheckMsisdnResponse> {
    return this.httpClient.post<CheckMsisdnResponse>(
      this.config.rechargesBackendUrl + this.ENDPOINT_FIND_OPERATOR_CHECK, rechargesValidRequest
    )
  }

  // ApplePay

  postCreateApplePaySession(validationURL: string): Observable<any> {
    return this.httpClient.post<any>(this.config.rechargesBackendUrl + 'payment/sessionCreate', validationURL);
  }

  postAuthorizeApplePayPayment(paymentToken: string): Observable<any> {
    return this.httpClient.post<any>(this.config.rechargesBackendUrl + 'payment/paymentAuthorize', paymentToken);
  }

  // Utils

  postAppendLog(logMessage: string) {
    this.httpClient.post(this.config.rechargesBackendUrl + 'payment/log', logMessage).subscribe(() => {
    });
  }

  validateMsisdnStatus(rechargesValidRequest: RechargesValidRequest): Promise<CheckMsisdnResponse> {
    return new Promise((resolve, reject) => {
      this.getMsisdnStatus(rechargesValidRequest)
        .subscribe((response: CheckMsisdnResponse) => resolve(response),
          (response) => reject(response.error));
    });
  }

  private getConfig(): Config {
    const request = new XMLHttpRequest();
    if (environment.production) {
      request.open('GET', 'env.json', false);
      request.send();
    } else {
      request.open('GET', 'assets/config/local/env.json', false);
      request.send();
    }
    if (request.status === 200) {
      let config: Config = JSON.parse(request.response);
      if (window.location.hostname.indexOf(config.virginDomain) > -1) {
        config.rechargesBackendUrl = "https://" + config.virginDomain + "/RechargeApplication/";
      }
      return config;
    } else {
      throw new Error('Request failed no configuration');
    }
  }

  public isVirgin(): boolean {
    if (window.location.href.indexOf(this.config.virginDomain) > -1 || window.location.href.indexOf("brand=virgin") > -1) {
      return true;
    } else {
      return false;
    }
  }

  public isUa(): boolean {
    if (window.location.href.indexOf("/uk/") > -1) {
      return true;
    } else {
      return false;
    }
  }

  public isEn(): boolean {
    if (window.location.href.indexOf("/en/") > -1) {
      return true;
    } else {
      return false;
    }
  }
  public getLanguage(){
    if(this.isEn()){
      return "en";
    }else if(this.isUa()){
      return "uk";
    }else{
      return "pl";
    }
  }

  public addVirginBrandToQueryParam(params: any): void {
    if (this.isVirgin()) {
      params.brand = "virgin";
    }
  }
}

export interface MsisdnStatusCache {
  msidn: string,
  response: CheckMsisdnResponse
}
