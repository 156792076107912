import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[focusable]'
})
export class FocusableDirective {

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) { }

  @HostListener('focus', ['$event'])
  onFocusEvent(event: any) {
    this.renderer.addClass(this.elementRef.nativeElement, 'focused');
  }

  @HostListener('blur', ['$event'])
  onBlurEvent(event: any) {
    this.renderer.removeClass(this.elementRef.nativeElement, 'focused');
  }

}
