import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {FormInputUtility} from "../../../shared/utility/form-input-utility";
import {AmountConfigData, ConfigData} from "../../../shared/data/config-data";
import {AppStateService} from "../../../shared/services/app-state.service";

@Component({
  selector: 'amount-picker',
  templateUrl: './amount-picker.component.html'
})
export class AmountPickerComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;

  @ViewChildren('amountValueInput') amountValuesInputs: QueryList<ElementRef>;

  amountConfig: AmountConfigData = {
    min: 0, max: 0, defined: [], default: 0
  };

  definedAmountsList: string[] = [];
  isCustomAmountVisible = false;

  constructor(private changeDetector: ChangeDetectorRef,
              private configService: AppStateService) {}

  ngOnInit(): void {
    this.getAmountControl().valueChanges
      .subscribe(() => { this.selectAmountTile(); });
    this.configService.getRechargesConfig()
      .then((configData: ConfigData) => {
        this.amountConfig = configData.amount;
        configData.amount.defined
          .forEach(amount => this.definedAmountsList.push(String(amount)));
        this.definedAmountsList.push($localize`:@@another:inna`);
        this.changeDetector.detectChanges();
        this.selectAmountTile();
      });
  }

  setAmountValue(event, amount) {
    const isNumber = !!Number(amount);
    this.isCustomAmountVisible = !isNumber;
    if (isNumber) { this.getAmountControl().setValue(amount);
    } else {
      this.getAmountControl().reset();
    }
  }

  getAmountSuffix(amount) {
    return Number(amount) ? ' zł' : '';
  }

  private selectAmountTile() {
    let amount: number = this.getAmountControl().value;
    if (amount == 0) return;
    if (this.amountValuesInputs && this.amountValuesInputs.length > 0) {
      const elementMatch = this.amountValuesInputs
        .map(inputElement => inputElement.nativeElement)
        .find(element => element.value == amount);
      if (elementMatch) { elementMatch.checked = true;
      } else {
        this.isCustomAmountVisible = true;
        this.amountValuesInputs.last.nativeElement.checked = true;
        this.changeDetector.detectChanges();
      }
    }
  }

  isAmountInputInvalid() {
    return FormInputUtility.isInputInvalid(this.formGroup.get('amount'));
  }

  getAmountErrorMsg() {
    return FormInputUtility.getErrorMsg(this.formGroup.get('amount'), FormInputUtility.AMOUNT_INPUT_MESSAGES);
  }

  getAmountControl() {
    return this.formGroup.get('amount');
  }
}
