import {Renderer2} from "@angular/core";

export class ScriptUtility {

  static appendHeadScript(renderer: Renderer2, document: Document, url: string) {
    const scriptElement = renderer.createElement('script');
    renderer.setAttribute(scriptElement, 'src', url);
    renderer.setAttribute(scriptElement, 'async', 'true');
    renderer.appendChild(document.head, scriptElement);
  }

  static appendHeadScriptWithBody(renderer: Renderer2, head: HTMLElement, innerHtml: string) {
    const scriptElement = renderer.createElement('script');
    renderer.setProperty(scriptElement, 'innerHTML', innerHtml);
    renderer.appendChild(head, scriptElement);
  }

  static appendBodyNoScriptWithBody(renderer: Renderer2, body: HTMLElement, innerHtml: string) {
    const scriptElement = renderer.createElement('noscript');
    renderer.setProperty(scriptElement, 'innerHTML', innerHtml);
    renderer.appendChild(body, scriptElement);
    // TODO crashing Angular - renderer.insertBefore(body, body.firstElementChild, scriptElement);
  }

}
