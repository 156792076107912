export class GoogleTagManager {

  private static GTM_ID = 'GTM-KL44LF';

  public static GTM_SCRIPT_CONTENT =
    "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
    "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
    "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
    "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
    "})(window,document,'script','dataLayer','" + GoogleTagManager.GTM_ID + "');";

  public static GTM_NOSCRIPT_CONTENT =
    "<iframe src=\"https://www.googletagmanager.com/ns.html?id=" + GoogleTagManager.GTM_ID + "\"\n" +
    "height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>";

}
