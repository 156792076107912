<div class="payment-selector text-center">

  <h2 id="choosePayment" class="text-left no-margin-top half-base-margin-bottom"
      [ngClass]="{'error' : paymentInstrumentInvalid}" i18n="choosePaymentMethod">Wybierz metodę płatności:</h2>
  <ol>
    <li *ngFor="let paymentMethod of paymentMethodsData; let i = index">
      <input class="payment-method" [ngClass]="'payment-method-' + paymentMethod.type"
             [id]="'payment-method-' + i" type="radio"
             name="payment-method" (click)="onPaymentMethodSelected(paymentMethod)"/>
      <label class="text-left" [for]="'payment-method-' + i">
        <div class="svg-wrp">
          <img [src]="getPaymentImageSrc(paymentMethod)" [alt]="paymentMethod.displayName">
        </div>
        <span>{{ getPaymentMethodWithLocalize(paymentMethod) }}</span>
        <span class="icon icon-check"></span>
      </label>
    </li>
  </ol>

</div>

<div *ngIf="selectedPaymentMethod" [ngSwitch]="selectedPaymentMethod.type">
  <transfer-method *ngSwitchCase="PAYMENT_INSTRUMENTS.TRANSFER"
                   [instrumentInvalid]="paymentInstrumentInvalid" [banksOptions]="selectedPaymentMethod.paymentOptions"
                   (bankSelectedEvent)="onBankSelected($event)"></transfer-method>

<!--  <google-pay-method *ngSwitchCase="PAYMENT_INSTRUMENTS.GOOGLE_PAY" [paymentAmount]="paymentAmount" [paymentForm]="paymentForm"></google-pay-method>-->

<!--  <apple-pay-method *ngSwitchCase="PAYMENT_INSTRUMENTS.APPLE_PAY" [paymentAmount]="paymentAmount" [paymentForm]="paymentForm"></apple-pay-method>-->
</div>

