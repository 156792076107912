import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaymentOptionsData} from "../../../../shared/data/recharges-response-data";

@Component({
  selector: 'transfer-method',
  templateUrl: './transfer-method.component.html'
})
export class TransferMethodComponent implements OnInit {
  @Output() bankSelectedEvent = new EventEmitter<PaymentOptionsData>();
  @Input() instrumentInvalid: boolean = false;
  @Input() banksOptions: PaymentOptionsData[] = [];

  constructor() { }

  ngOnInit() {

  }
  selectBank(bankPayment) {
    this.bankSelectedEvent.emit(bankPayment);
  }
}
