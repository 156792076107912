import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CookieDataService} from "./cookie-data.service";

@Injectable()
export class PageComponentService {

  constructor(private httpClient: HttpClient,
              private cookieService: CookieDataService) { }

/*  fetchPageComponents(cacheExpireTimeInHours: number): Promise<PageRemoteElements> {
    let headers = new HttpHeaders();
    if (!this.cookieService.getComponentsCacheCookieOrCreate(cacheExpireTimeInHours)) {
      headers.set('Cache-Control', 'no-cache');
      headers.set('Pragma', 'no-cache');
    }
    return new Promise<PageRemoteElements>((resolve, reject) => {
      this.httpClient.get(environment.REMOTE_RES_BASE_URL + '/external/external-menu/external-menu-b2c-base.html',
        { headers: headers, responseType: 'text' }
      ).subscribe(htmlSource => {
        const parser = new DOMParser();
        const pageDocument = parser.parseFromString(htmlSource, 'text/html');
        const headerComponent = pageDocument.getElementById('header');
        const footerComponent = pageDocument.getElementById('footer');
        const cookieComponent = pageDocument.getElementById('cookies');
        if (headerComponent && footerComponent && cookieComponent) {
          resolve({ header: headerComponent, footer: footerComponent, cookie: cookieComponent });
        }
        reject('Cannot parse remote page components!');
      },() => reject('Cannot fetch remote page components!'));
    });

  }*/
}

export interface PageRemoteElements {
  header: HTMLElement, footer: HTMLElement, cookie: HTMLElement
}
