import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RegulationData} from "../../../shared/data/recharges-response-data";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {fromEvent} from "rxjs";

@Component({
  selector: 'regulations',
  templateUrl: './regulations.component.html'
})
export class RegulationsComponent implements OnInit, AfterViewInit {
  @Output() selectedEvent = new EventEmitter<boolean>();
  @Input() paymentInvalid = false;
  @Input() paymentConsent: string = '';
  @Input() paymentRegulations: RegulationData[] = [];

  showConsent = false;
  consentHtml: SafeHtml;
  regulationContent: SafeHtml;

  constructor(private elementRef: ElementRef,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.setRegulation();
  }

  setRegulation() {
    let consentData = $localize`:@@goNextRegulation:Przechodząc dalej, oświadczam, że zapoznałem się z %s i %s. %s Usługę doładowań świadczy PayU S.A. z siedzibą w Poznaniu.`;
    for (let regulation of this.paymentRegulations) {
      if (regulation.title == "Regulamin usługi płatniczej") {
        regulation.title = $localize`:@@paymentRegulation:Regulamin usługi płatniczej`;
      } else if (regulation.title == "Regulamin doładowań") {
        regulation.title = $localize`:@@rechargeRegulation:Regulamin doładowań`;
      } else if (regulation.title == "Administratorem danych osobowych jest PayU S.A.") {
        continue;
      }
      let link = regulation.link;
      consentData = consentData.replace("%s",
        '<a href="' + link + '" class="regulation-' + regulation.id + '" >' + regulation.title + '</a>');
    }

    for (let regulation of this.paymentRegulations) {
      if (regulation.title == "Administratorem danych osobowych jest PayU S.A.") {
        regulation.title = $localize`:@@adminRegulation:Administratorem danych osobowych jest PayU S.A.`;
      } else {
        continue;
      }
      let link = regulation.link;
      regulation.content = $localize`:@@adminRegulationContent:Administratorem Twoich danych osobowych jest PayU S.A. z siedzibą w Poznaniu (60-166), przy ul. Grunwaldzkiej 186 ("PayU"). Twoje dane osobowe będa przetwarzane w celu doładowania konta telefonicznego prowadzonego przez danego operatora sieci telefonii komórkowej, realizacji transakcji płatniczej, powiadamiania Cię o statusie realizacji Twojej płatności, rozpatrywania reklamacji, a także w celu wypełnienia obowiązków prawnych ciążących na PayU.Odbiorcami Twoich danych osobowych mogą być podmioty współpracujące z PayU w procesie realizacji usługi doładowania i płatności. W zależności od wybranej przez Ciebie metody płatności mogą to być: banki, instytucje płatnicze, instytucje pożyczkowe, organizacje kart płatniczych, schematy płatnicze), ponadto podmioty wspierające działalność PayU tj. dostawcy infrastruktury IT, dostawcy narzędzi do analizy ryzyka płatności a także podmioty uprawnione do ich otrzymania na mocy obowiązujacych przepisów prawa, w tym właściwe organy wymiaru sprawiedliwości. Przysługuje Tobie prawo dostępu do danych, a także do ich sprostowania, ograniczenia ich przetwarzania, zgłoszenia sprzeciwu wobec ich przetwarzania, niepodlegania zautomatyzowanemu podejmowaniu decyzji w tym profilowania oraz do przenoszenia i usunięcia danych. Podanie danych jest dobrowolne jednak niezbądne do realizacji usługi doładowania i płatności, a brak podania danych może skutkować niezrealizowaniem usług. Więcej informacji o zasadach przetwarzania Twoich danych osobowych przez PayU znajdziesz w `;
      regulation.content = regulation.content;
      if (regulation.content.indexOf("Administratorem Twoich danych ") > -1) {
        regulation.content = regulation.content + "<a href=\"https://poland.payu.com/nota-o-prywatnosci-payu/\">Polityce Prywatności</a>.";
      }else if(regulation.content.indexOf("The administrator of your personal data") > -1){
        regulation.content = regulation.content + "<a href=\"https://poland.payu.com/nota-o-prywatnosci-payu/\">Privacy Policy</a>.";
      }
      else {
        regulation.content = regulation.content + "<a href=\"https://poland.payu.com/nota-o-prywatnosci-payu/\">Політиці конфіденційності</a>.";
      }
      link = 'javascript:void(0)';

      consentData = consentData.replace("%s",
        '<a href="' + link + '" class="regulation-' + regulation.id + '" >' + regulation.title + '</a>');
    }

    this.consentHtml = this.sanitizer.bypassSecurityTrustHtml(consentData)

    this.paymentRegulations
      .filter(regulation => regulation.content)
      .map(regulation => {
        return {
          id: regulation.id,
          element: this.elementRef.nativeElement.getElementsByClassName('regulation-' + regulation.id)
        }
      })
      .filter(regulation => regulation.element != null)
      .forEach(regulation => {
        fromEvent(regulation.element, 'click')
          .subscribe(() => this.showConsentContent(regulation.id));
      });
  }

  ngAfterViewInit(): void {
    this.paymentRegulations
      .filter(regulation => regulation.content)
      .map(regulation => {
        return {
          id: regulation.id,
          element: this.elementRef.nativeElement.getElementsByClassName('regulation-' + regulation.id)
        }
      })
      .filter(regulation => regulation.element != null)
      .forEach(regulation => {
        fromEvent(regulation.element, 'click')
          .subscribe(() => this.showConsentContent(regulation.id));
      });
  }

  onAgreementSelected(checked: boolean) {
    this.selectedEvent.emit(checked);
  }

  onToggleConsent() {
    this.showConsent = !this.showConsent;
  }

  private showConsentContent(id: Number) {
    const regulation = this.paymentRegulations.find(regulation => regulation.id == id);
    if (regulation) {
      this.regulationContent = (!this.regulationContent) ? this.sanitizer.bypassSecurityTrustHtml(regulation.content) : '';
    }
  }

}
