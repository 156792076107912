import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {FormInputUtility} from "../../../shared/utility/form-input-utility";
import {GiftAmountPipe} from "../../../shared/pipes/gift-amount.pipe";

@Component({
  selector: 'gift-banner',
  templateUrl: './gift-banner.component.html'
})
export class GiftBannerComponent implements OnInit, AfterViewInit {
  @Input() formGroup: UntypedFormGroup;

  readonly GIFT_AMOUNT_THRESHOLD = GiftAmountPipe.GIFT_AMOUNT_THRESHOLD;

  giftAmount = 0;

  constructor(private giftPipe: GiftAmountPipe) { }

  ngOnInit(): void {
    this.giftAmount = this.giftPipe.transform(this.formGroup.get('amount').value)
  }

  ngAfterViewInit(): void {
    this.formGroup.get('amount').valueChanges
      .subscribe(amount =>
        this.giftAmount = this.giftPipe.transform(amount));
  }

  isAmountInputInvalid() {
    return FormInputUtility.isInputInvalid(this.formGroup.get('amount'));
  }

}
