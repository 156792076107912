export enum RECHARGE_STATE {
  AMOUNT, PAYMENT, BLIK
}

export const RECHARGE_STATE_NAME = {
  AMOUNT: 'AMOUNT',
  PAYMENT: 'PAYMENT',
  BLIK: 'BLIK',
  UNKNOWN: 'UNKNOWN'
};
