<section class="topup-section container">
  <div class="row">
    <div class="small-12 columns">
      <h1 class="text-bold text-light-black" i18n="Summarize">Podsumowanie</h1>
    </div>
  </div>
  <section class="topup-section container">
    <div class="row align-center">
      <div class="topup-container small-12 large-5 columns text-center">

        <div *ngIf="paymentStatus" class="topup-summary topup-summary-success inner-wrapper">
          <div class="status">
            <span class="icon" [ngClass]="paymentSuccess ? 'icon-tick-new' : 'icon-cross-new'"></span>
          </div>

          <div *ngIf="paymentSuccess">
            <div *ngIf="rechargeAmount">
              <h2 i18n="ThankYouForPaymentAmount" class="no-margin-bottom half-base-margin-top">Dziękujemy za doładowanie kwotą:</h2>
              <p class="amount-base"><strong>{{ rechargeAmount }} zł</strong></p>
            </div>

            <h2 i18n="ThankYouForPaymentNoAmount" *ngIf="!rechargeAmount" class="no-margin-bottom half-base-margin-top">Dziękujemy za doładowanie</h2>

            <div *ngIf="rechargeGiftAmount" class="badge-yellow" hidden>
              <span class="icon icon-gift-2"></span>
              <p i18n="ThankYouGift" class="no-margin-bottom">
                Dodatkowo na Twoje <br>
                konto dodaliśmy <strong class=""> {{ rechargeGiftAmount }} zł</strong>,<br>
                które możesz wykorzystać <br>
                na rozmowy i SMS-y.
              </p>
            </div>

            <p *ngIf="rechargeValidation" i18n="accountValidTime"
               class="half-base-margin-top no-margin-bottom">
              Ważność konta:<strong class=""> {{ rechargeValidation }}</strong>
            </p>
          </div>

          <p *ngIf="paymentStatusMessage"
             class="half-base-margin-top no-margin-bottom"
             [innerHtml]="paymentStatusMessage"></p>
        </div>

        <div *ngIf="!paymentStatus && paymentBlikInstrument" class="topup-payment inner-wrapper">
          <img class="blik-logo" src="assets/images/payment_blik.svg" alt="BLIK">
          <img src="assets/images/loader.gif" alt="loader" style="display: block; margin: 1rem auto;"/>
          <div class="blik-message text-center">
            <p i18n="ConfirmTransaction">Zatwierdź transakcję<br class="hide-for-medium"> na swoim urządzeniu.</p>
          </div>
        </div>

        <div *ngIf="!paymentStatus && !paymentBlikInstrument" class="topup-summary inner-wrapper">
          <img src="assets/images/loader.gif" alt="loader"/>
        </div>
      </div>
    </div>
  </section>
</section>
