
<section class="topup-section container">
  <div class="row align-center" >
    <div class="topup-container text-center">
      <div class="topup-payment inner-wrapper">

        <form class="height-anim" [formGroup]="formGroup" (ngSubmit)="onFormSubmit()"
              [ngClass]="{ 'height-anim-closing': isSubmitted }" #paymentFormElementRef>

          <payment-methods *ngIf="paymentMethods"
                           [paymentInstrumentInvalid]="isInstrumentInvalid()"
                           [paymentInitialType]="formGroup.get('instrument').value"
                           [paymentForm]="formGroup"
                           [paymentAmount]="paymentAmount"
                           [paymentMethodsData]="paymentMethods"
                           (selectEvent)="onPaymentMethodSelected($event)"></payment-methods>

          <img *ngIf="!paymentMethods"
               src="assets/images/loader.gif" alt="payments-loader"/>

          <div class="payment-email input-with-floating-label"
               [ngClass]="{'error' : isEmailInputInvalid()}">
            <input class="input-email" type="text" id="payment-email" name="topups-email"
                   placeholder="Wpisz swój adres e-mail" formControlName="email" focusable >
            <label for="payment-email" i18n="setEmailAdress">Wpisz swój adres e-mail</label>
            <small *ngIf="isEmailInputInvalid()"
                   role="alert" class="form-message">{{ getEmailErrorMsg() }}</small>
          </div>

          <div class="payment-agreements">

            <regulations *ngIf="paymentConsents && paymentRegulations" [paymentInvalid]="isAgreementInputInvalid()"
                         [paymentConsent]="paymentConsents"
                         [paymentRegulations]="paymentRegulations"
                         (selectedEvent)="onAgreementChanged($event)" #regulationsRef></regulations>

            <div class="custom-checkbox small text-left">
              <input class="payment-agreement" id="payment-store"
                     type="checkbox" formControlName="store" focusable >
              <label for="payment-store" i18n="saveFormData">Zapamiętaj dane wpisane w formularzu</label>
            </div>
          </div>

          <!-- IF paymentMethods -->
          <div class="steps" buttonResizer #paymentButtonsContainerRef>
            <button *ngIf="isPayBtnVisible" type="submit" id="submit-step2" class="button medium no-margin" i18n="pay">
              Zapłać <span class="icon icon-chevron-right"></span>
            </button>
            <button [hidden]="!isApplePayBtnVisible" type="button" lang="pl" class="apple-pay-btn"
                    (click)="onApplePayBtnClick()" ></button>
            <button id="step-back" type="button" class="button medium transparents-white no-margin"
                    (click)="onReturnToRecharge()" i18n="back">Powrót
            </button>
          </div>
          <div style="margin-top: 0.5rem" *ngIf="paymentErrorMsg" >
            <small role="alert" class="error-msg-text">{{ paymentErrorMsg }}</small>
          </div>

        </form>

        <img *ngIf="isSubmitted" src="assets/images/loader.gif" alt="loader" />
      </div>
    </div>
  </div>
</section>

