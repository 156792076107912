import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {FormInputUtility} from "../../shared/utility/form-input-utility";
import {AppRestService} from "../../shared/services/app-rest.service";
import {
  AppSettings,
  PaymentOptionsData,
  PlayPaymentMethodData,
  RechargesPaymentMethodsRequest,
  RechargesPaymentMethodsResponse,
  RegulationData
} from "../../shared/data/recharges-response-data";
import {FormUtility} from "../../shared/utility/form-utility";
import {PaymentMethodsUtility} from "../../shared/utility/payment-methods-utility";
import {GooglePayApiService} from "../../shared/api/google-pay-api.service";
import {INSTRUMENT_CODES} from "../recharge.component";
import {ApplePayApiService} from "../../shared/api/apple-pay-api.service";
import {GoogleAnalyticsService} from "../../shared/services/google-analytics.service";
import {Subscription} from "rxjs";
import {RegulationsComponent} from "./regulations/regulations.component";

@Component({
  selector: 'payment-section',
  templateUrl: './payment-section.component.html'
})
export class PaymentSectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() submitEvent = new EventEmitter<any>();
  @Input() formGroup: UntypedFormGroup;
  @Input() paymentAmount: number = 0;

  @ViewChild('paymentFormElementRef', { static: true }) paymentFormElementRef: ElementRef;
  @ViewChild('paymentButtonsContainerRef', { static: true }) paymentButtonsContainerRef: ElementRef;
  @ViewChild('regulationsRef') regulationRef: RegulationsComponent;

  googlePayBtnElement: HTMLElement;

  isPayBtnVisible: boolean = true;
  isApplePayBtnVisible: boolean = false;
  isSubmitted: boolean = false;
  paymentErrorMsg: string = '';

  paymentMethods: PlayPaymentMethodData[];
  paymentConsents: string;
  paymentRegulations: RegulationData[];

  paymentMethodsSub: Subscription;

  constructor(private googleAnalyticsService: GoogleAnalyticsService,
              private rechargesService: AppRestService,
              private googlePayService: GooglePayApiService,
              private applePayService: ApplePayApiService,
              private detectorRef: ChangeDetectorRef,
              private renderer: Renderer2) { }

  ngOnInit() {
    let rechargesPaymentMethodsRequest: RechargesPaymentMethodsRequest = new RechargesPaymentMethodsRequest();
    if(this.rechargesService.isVirgin()){
      rechargesPaymentMethodsRequest.branding = AppSettings.BRAND_VIRGIN;
    }else{
      rechargesPaymentMethodsRequest.branding = AppSettings.BRAND_PLAY;
    }
    this.paymentMethodsSub = this.rechargesService.getPaymentMethods(rechargesPaymentMethodsRequest)
      .subscribe((response: RechargesPaymentMethodsResponse) => {
        this.paymentConsents = response.paymentMethods[0].consents[0].content;
        this.paymentRegulations = response.paymentMethods[0].consents[0].regulations;
        this.paymentMethods = PaymentMethodsUtility.filterPaymentMethods(response.paymentMethods);
        this.setupGooglePayBtn();
      });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    if (this.paymentMethodsSub) this.paymentMethodsSub.unsubscribe();
  }

  private setupGooglePayBtn() {
    if (this.googlePayService.isApiReady()) {
      const paymentMethodData = PaymentMethodsUtility.getActivePaymentInstrument(this.paymentMethods, INSTRUMENT_CODES.GOOGLE_PAY);
      const merchantId = GooglePayApiService.getPaymentMerchantId(paymentMethodData);
      if (merchantId) {
        this.googlePayService.initPaymentButton(this, merchantId, this.onGooglePayBtnClick)
          .then(btnElement => {
            btnElement.hidden = true;
            const containerEl = this.paymentButtonsContainerRef.nativeElement;
            this.renderer.insertBefore(containerEl, btnElement, containerEl.firstChild);
            containerEl.dispatchEvent(new Event('btnInit', { bubbles: true }));
            this.setGooglePayBtnVisibility(this.formGroup.get('instrument').value)
          })
          .catch((err) => {
            console.error('Wystąpił błąd! [Google Pay]');
            console.error(err);
          });
      }
    }
  }

  // payment methods

  onPaymentMethodSelected(paymentData: PaymentOptionsData) {
    if(paymentData != null && paymentData.customConsents != null){
      this.regulationRef.paymentRegulations = paymentData.customConsents[0].regulations;
      this.regulationRef.paymentConsent = paymentData.customConsents[0].content;
      this.regulationRef.setRegulation();
    }else{
      this.regulationRef.paymentRegulations = this.paymentRegulations;
      this.regulationRef.paymentConsent = this.paymentConsents;
      this.regulationRef.setRegulation();
    }
    this.paymentErrorMsg = '';
    const instrumentCode = paymentData ? paymentData.code : null;
    this.formGroup.get('instrument').setValue(instrumentCode);
    this.setPaymentBtnVisibility(instrumentCode);
    this.setGooglePayBtnVisibility(instrumentCode);
    this.setApplePayBtnVisibility(instrumentCode);
  }

  private setPaymentBtnVisibility(instrumentCode: string) {
    this.isPayBtnVisible = (instrumentCode != INSTRUMENT_CODES.GOOGLE_PAY &&
      instrumentCode != INSTRUMENT_CODES.APPLE_PAY);
    this.detectorRef.detectChanges();
  }

  private setGooglePayBtnVisibility(instrumentCode: string) {
    const visible = (instrumentCode == INSTRUMENT_CODES.GOOGLE_PAY);
    if (!this.googlePayBtnElement)
      this.googlePayBtnElement = this.paymentButtonsContainerRef.nativeElement
        .getElementsByClassName('gpay-button')[0];
    if (this.googlePayBtnElement)
      this.renderer.setProperty(this.googlePayBtnElement, 'hidden', !visible);
  }

  private setApplePayBtnVisibility(instrumentCode: string) {
    this.isApplePayBtnVisible = (instrumentCode == INSTRUMENT_CODES.APPLE_PAY);
  }

  // agreement event

  onAgreementChanged(checked: boolean) {
    this.formGroup.get('agreement').setValue(checked);
  }

  // payment action

  private onGooglePayBtnClick() {
    this.paymentErrorMsg = '';
    FormUtility.markFormAsTouched(this.formGroup);
    if (this.formGroup.valid) {
      this.googlePayService.createNewPayment(String(this.paymentAmount))
        .then(token => {
          this.formGroup.get('authCode').setValue(token);
          this.submitFormData();
        })
        .catch((err) => {
          this.paymentErrorMsg = $localize`:@@ErrorGooglePay:Płatność Google Pay nie została wykonana`;
          console.error(err);
        });
    } else {
      this.onFormSubmitFail();
    }
  }

  onApplePayBtnClick() {
    FormUtility.markFormAsTouched(this.formGroup);
    if (this.formGroup.valid) {
      this.applePayService.createNewPayment(this.paymentAmount)
        .then(token => {
          this.formGroup.get('authCode').setValue(token);
          this.submitFormData();
        })
        .catch(reason => {
          console.error(reason); // TODO tmp
          this.paymentErrorMsg = $localize`:@@ErrorApplePay:Płatność Apple Pay nie została wykonana`
        });
    } else {
      this.onFormSubmitFail();
    }
  }

  onFormSubmit() {
    FormUtility.markFormAsTouched(this.formGroup);
    if (!this.validateAuthorizationCode()) {
      this.onFormSubmitFail();
    } else {
      if (this.formGroup.valid) { this.submitFormData() } else { this.onFormSubmitFail(); }
    }
  }

  private validateAuthorizationCode() {
    const instrumentCode = this.formGroup.get('instrument').value;
    if (instrumentCode == INSTRUMENT_CODES.GOOGLE_PAY || instrumentCode == INSTRUMENT_CODES.APPLE_PAY) {
      return (this.formGroup.get('authCode').value)
    }
    return true;
  }

  private submitFormData() {
    this.googleAnalyticsService.sendNewPaymentAnalytics(String(this.paymentAmount), this.formGroup.value.instrument);
    // update form max height for proper collapse animation
    const scrollHeight = this.paymentFormElementRef.nativeElement.scrollHeight + 100;
    this.renderer.setStyle(this.paymentFormElementRef.nativeElement, 'max-height', scrollHeight + 'px');
    // begin animation
    this.isSubmitted = true;
    this.submitEvent.emit(this.formGroup.value);
    // this.wrapperContainerRef.nativeElement.scrollIntoView(true);
  }

  private onFormSubmitFail() {
    if (this.formGroup.get('instrument').invalid)
      this.paymentErrorMsg = $localize`:@@NoPaymentsChoosen:Nie wybrano formy płatności`;
    console.log('Błędne dane formularza');
  }

  onReturnToRecharge() {
    this.submitEvent.emit(null);
  }

  // form getters

  isEmailInputInvalid() {
    return FormInputUtility.isInputInvalid(this.formGroup.get('email'));
  }

  getEmailErrorMsg() {
    return FormInputUtility.getErrorMsg(this.formGroup.get('email'), FormInputUtility.EMAIL_ERROR_MSG);
  }

  isInstrumentInvalid() {
    return FormInputUtility.isInputInvalid(this.formGroup.get('instrument'));
  }

  isAgreementInputInvalid() {
    return FormInputUtility.isInputInvalid(this.formGroup.get('agreement'));
  }

}
