<div *ngIf="isConfigLoaded">
  <section class="topup-section container" *ngIf="!this.restService.isVirgin()">
    <div class="row">
      <div class="small-12 columns">
        <h1 class="text-bold text-light-black">
          {{getMainTitle()}} </h1>
      </div>
    </div>
  </section>

  <div [ngSwitch]="currentRechargeState">
    <amount-section *ngSwitchCase="RECHARGE_STATE.AMOUNT"
                    [formGroup]="amountFormGroup"
                    (submitEvent)="onRechargeDataCollected($event)"></amount-section>
    <payment-section *ngSwitchCase="RECHARGE_STATE.PAYMENT"
                     [formGroup]="paymentFormGroup"
                     [paymentAmount]="paymentRequest.amount"
                     (submitEvent)="onPaymentDataCollected($event)"></payment-section>
    <blik-section *ngSwitchCase="RECHARGE_STATE.BLIK"
                  (submitEvent)="onBlikDataCollected($event)"></blik-section>
  </div>

</div>

<div *ngIf="!isConfigLoaded" style="display: flex; align-items: center; justify-content: center">
  <img src="assets/images/loader.gif" alt="loader"/>
</div>
