import {ParamData} from "./recharges-request-data";

// RechargesValidDate

export class AppSettings {
  public static BRAND_PLAY = 'PLAY';
  public static BRAND_VIRGIN = 'P4VIRGIN'
}

export interface RechargesValidResponse {
  msisdn: string;
  validTo: string;
}

// RechargesPaymentMethodsData

export class RechargesPaymentMethodsRequest {
  branding: string;
  language: string;
}

export interface RechargesPaymentMethodsResponse {
  paymentMethods: PlayPaymentMethodData[];
  commission: Commission;
}

export interface PlayPaymentMethodData {
  type: string; // PlayPaymentType???
  displayName: string;
  paymentOptions: PaymentOptionsData[];
  consents: Consent;
}

export interface Consent{
  regulations: RegulationData[];
  group:string;
  content: string;
  required: boolean;
}
export interface Commission{
  agent: String;
  value: number;
}

export interface PaymentOptionsData {
  name: string;
  code: string;
  status: string; // PaymentStatusEnum???
  iconUrl: string;
  customParams: ParamData[];
  customConsents: Consent[];
}

export interface RegulationData {
  id: number;
  title: string;
  link: string;
  content: string;
}

// RechargesNewPaymentData

export interface RechargesNewPaymentResponse {
  url: string;
  params: ParamData[];
  paymentId: string;
}

// RechargesStatusResponse

export interface RechargesStatusResponse {
  status: string; // PaymentCheckStatus???
  pending: boolean;
  paymentId: string;
  message: string;
}

// Check Msisdn

export interface CheckMsisdnResponse {
  belongsToP4: boolean;
  prepaid: boolean;
  message: string;
  validTo: string;
}

export interface ResponseError {
  responseMessage: string,
  responseCode: string
}
