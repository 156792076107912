import {AbstractControl, UntypedFormGroup} from "@angular/forms";
import {AppRestService} from "../services/app-rest.service";
import {CheckMsisdnResponse, ResponseError} from "../data/recharges-response-data";

export class FormUtility {

  // /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;// /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

  private static readonly REG_EXP = {
    EMAIL: /^[a-zżźćńółęąś0-9._%\-+]{1,256}@[a-zżźćńółęąś0-9][a-zżźćńółęąś0-9\-]{0,64}(.[a-zżźćńółęąś0-9][a-zżźćńółęąś0-9\-]{0,25})+$/i,
    MSISDN: /^(\+48\s(\d{3})\s(\d{3})\s(\d{3}))$/,
    MSISDN_SHORT: /^(\d{9})$/,
    BLIK: /\d{6}/
  };

  // EMAIL

  static validateEmail(c: AbstractControl) {
    if (!c.value) return null;
    const email = c.value.trim();
    if (email != '' && email.length <= 5 || !FormUtility.REG_EXP.EMAIL.test(email)) return {incorrectMailFormat: true};
    return null;
  }

  // AMOUNT

  static validateAmount(minAmount: number, maxAmount: number) {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (!Number(c.value)) return {isIncorrect: true};
      if (c.value < minAmount) return {isAmountTooLow: true};
      if (c.value > maxAmount) return {isAmountTooHigh: true};
      return null;
    };
  }

  // MSISDN

  static validateMsisdn(c: AbstractControl) {
    if (!c.value) return {incorrectMsisdnFormat: true};
    if (FormUtility.REG_EXP.MSISDN_SHORT.test(c.value) ||
      FormUtility.REG_EXP.MSISDN.test(c.value)) return null;
    return {incorrectMsisdnFormat: true};
  }

  static validateMsisdnStatus(rechargesService: AppRestService, c: AbstractControl): Promise<any> {
    return new Promise<any>((resolve) => {
      if (c.hasError('incorrectMsisdnFormat')) {
        resolve(null);
      } else {
        const msisdn: string = FormUtility.getMsisdn(c.value);
        if (rechargesService.msisdnStatusCache && msisdn == rechargesService.msisdnStatusCache.msidn) {
          return resolve(FormUtility.parseMsisdnStatusRequest(c, rechargesService.msisdnStatusCache.response));
        }
        const amount = c.parent.get('amount').value;
        rechargesService.validateMsisdnStatus({
          msisdn: msisdn,
          amount: amount
        })
          .then((response: CheckMsisdnResponse) => {
            rechargesService.msisdnStatusCache = {
              msidn: msisdn, response: response
            };
            return resolve(FormUtility.parseMsisdnStatusRequest(c, response));
          })
          .catch((error: ResponseError) => {
            rechargesService.msisdnStatusCache = null;
            return resolve({notBelongsToP4: true});
          });
      }
    });
  }

  static parseMsisdnStatusRequest(c: AbstractControl, response: CheckMsisdnResponse) {
    if (response.validTo) {
      c.parent.get('validTo')
        .setValue(response.validTo);
    }
    if (!response.belongsToP4) {
      return {notBelongsToP4: true};
    }
    if (!response.prepaid) {
      return {isNotPrepaid: true};
    }
    return null;
  }

  // INSTRUMENT

  static validateInstrument(c: AbstractControl) {
    return (!c.value) ? {required: true} : null;
  }

  // BLIK

  static validateBlik(c: AbstractControl) {
    if (!c.value) return {required: true};
    if (FormUtility.REG_EXP.BLIK.test(c.value)) return null;
    return {incorrectBlik: true};
  }

  // UTILITY

  static markFormAsTouched(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field =>
      formGroup.get(field).markAsTouched({onlySelf: true}));
  }

  static removeFormControls(formGroup: UntypedFormGroup) {
    if (formGroup) Object.keys(formGroup.controls)
      .forEach(field => formGroup.removeControl(field));
  }

  static trimSpaces(value: string) {
    return value.replace(/ /g, '');
  }

  static getMsisdn(msisdn: string) {
    if (!msisdn) return '';
    let value = msisdn.replace(/[_+]/g, '');
    if (!value.startsWith('48 ')) value = '48 ' + value;
    return this.trimSpaces(value);
  }

}
