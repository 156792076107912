import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {FormInputUtility} from "../../shared/utility/form-input-utility";
import {FormUtility} from "../../shared/utility/form-utility";
import Inputmask from "inputmask/dist/inputmask/inputmask.date.extensions.js";

@Component({
  selector: 'blik-section',
  templateUrl: './blik-section.component.html'
})
export class BlikSectionComponent implements OnInit, AfterViewInit {
  @Output() submitEvent = new EventEmitter<any>();

  @ViewChild('blikInput', { static: true }) blikInput: ElementRef;

  readonly BLIK_MASK = '999999';

  blikForm: UntypedFormGroup;
  isSubmitted = false;

  constructor() { }

  ngOnInit() {
    this.blikForm = new UntypedFormGroup({
      blik: new UntypedFormControl('', [Validators.required, FormUtility.validateBlik])
    });
  }

  ngAfterViewInit(): void {
    Inputmask({mask: this.BLIK_MASK, showMaskOnHover: false}).mask(this.blikInput.nativeElement);
  }

  onFormSubmit() {
    FormUtility.markFormAsTouched(this.blikForm);
    if (this.blikForm.valid) {
      this.isSubmitted = true;
      this.submitEvent.emit(this.blikForm.get('blik').value);
    }
  }

  onReturnBtn() {
    this.submitEvent.emit(null);
  }

  isBlikInvalid() {
    return FormInputUtility.isInputInvalid(this.blikForm.get('blik'));
  }

  getBlikErrorMsg() {
    return FormInputUtility.getErrorMsg(this.blikForm.get('blik'), FormInputUtility.BLIK_ERROR_MSG);
  }

}
