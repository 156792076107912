<section class="topup-section container">
  <div class="row align-center">
    <div class="value-wrapper">

      <div class="topup-type-container">
        <ol id="topup-type-options">
          <li *ngFor="let tab of AMOUNT_TAB_TITLES_LIST">
            <input [id]="'topup-type-' + tab" name="topup-type" type="radio"
                   [checked]="(selectedTab == tab) ? 'checked' : ''">
            <label class="text-center" [for]="'topup-type-' + tab" (click)="selectedTab = tab">
              <span i18n="add">Doładuj {{ tab }}</span>
            </label>
          </li>
        </ol>
      </div>

      <div class="row align-center">

        <div class="topup-container text-center">

          <div class="topup-value inner-wrapper">
            <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
              <div class="input-with-floating-label main-phone-number"
                   [ngClass]="{'error' : isMsisdnInputInvalid()}">
                <input class="input-phone input-phone-main" type="tel" id="topups-phone-2" formControlName="msisdn"
                       placeholder="Wpisz numer telefonu" #msisdnInput >
                <label for="topups-phone-2" i18n="insertPhoneNumber">Wpisz numer telefonu</label>
                <small *ngIf="isMsisdnInputInvalid()"
                       role="alert" class="form-message">{{ getMsisdnErrorMsg() }}</small>
              </div>
              <h2 class="text-left no-margin" i18n="Amount">Za ile chcesz doładować?</h2>

              <div class="tab-content-wrapper">

                <amount-picker [formGroup]="formGroup"></amount-picker>

                <gift-banner [formGroup]="formGroup" hidden></gift-banner>

              </div>

              <button type="submit" class="button medium" i18n="@@goButton">Dalej<span class="icon icon-chevron-right"></span>
              </button>
            </form>

            <p class="payment-info text-center no-margin" i18n="@@PayUInformation" *ngIf="!this.restService.isVirgin()">
              Doładowania Play są realizowane <br class="show-for-small-only">za pośrednictwem
              <img class="logo" src="assets/images/logo_payu.svg" alt="Logo PayU">
            </p>
            <p class="payment-info text-center no-margin" i18n="@@PayUInformationVirgin" *ngIf="this.restService.isVirgin()" [ngStyle]="{'width': isPl() ? 'max-content' : 'unset' }">
              Doładowania Virgin Mobile są realizowane <br class="show-for-small-only">za pośrednictwem
              <img class="logo" src="assets/images/logo_payu.svg" alt="Logo PayU">
            </p>

          </div>
        </div>

      </div>

    </div>
  </div>
</section>


