import {Injectable, NgZone} from '@angular/core';
import {INSTRUMENT_CODES} from "../../recharge/recharge.component";

declare var dataLayer;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private ngZone: NgZone) { }

  sendAmountSelectionAnalytics(amount: string, voice: boolean) {
    this.appendTagManagerScript(this.getAmountSelectionData(String(amount), voice));
  }

  sendNewPaymentAnalytics(amount: string, instrumentCode: string) {
    this.appendTagManagerScript(this.getNewPaymentData(String(amount), instrumentCode));
  }

  sendRechargeStatusAnalytics(amount: string, instrumentCode: string) {
    this.appendTagManagerScript(this.getRechargeStatusData(String(amount), instrumentCode));
  }

  private getAmountSelectionData(amount: string, voice: boolean) {
    return {
      'event' : 'dlTopUpAmount',
      'Amount': amount,
      'Tab': voice ? 'Doładuj telefon' : 'Doładuj Internet'
    };
  }

  private getNewPaymentData(amount: string, instrumentCode: string) {
    return {
      'event' : 'dlPaymentMethod',
      'PaymentMethod': GoogleAnalyticsService.getPaymentMethodName(instrumentCode),
      'PaymentAmount': amount
    }
  }

  private getRechargeStatusData(amount: string, instrumentCode: string) {
    return {
      'event' : 'dlTransaction',
      'Amount': amount,
      'PaymentMethod': GoogleAnalyticsService.getPaymentMethodName(instrumentCode),
    }
  }

  private appendTagManagerScript(data: any) {
    this.ngZone.runOutsideAngular(() => {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push(data);
    });
  }

  private static getPaymentMethodName(instrumentCode: string) {
    switch (instrumentCode) {
      case INSTRUMENT_CODES.TRANSFER: return $localize`:@@bankPayment:Przelew bankowy`;
      case INSTRUMENT_CODES.GOOGLE_PAY: return 'Google Play';
      case INSTRUMENT_CODES.APPLE_PAY: return 'Apple Pay';
      case INSTRUMENT_CODES.CARD: return $localize`:@@cardPayment:Płatność kartą`;
      case INSTRUMENT_CODES.BLIK: return 'Blik';
    }
    return 'Przelew bankowy'; // TODO is incorrect ???
  }
}
