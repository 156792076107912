import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UntypedFormGroup} from "@angular/forms";
import {CookieDataService} from "./cookie-data.service";
import {RECHARGE_STATE, RECHARGE_STATE_NAME} from "../utility/recharge.state";
import {ConfigData} from "../data/config-data";
import {AppRestService} from "./app-rest.service";

@Injectable()
export class AppStateService {

  readonly ENDPOINT_UTILITY_CONFIG = 'utils/config';

  rechargeStateNotifier = new EventEmitter<string>();

  configData: ConfigData = null;
  configPromise: Promise<ConfigData> = null;

  constructor(private httpClient: HttpClient,
              private cookieDataService: CookieDataService,
              private appRestService: AppRestService) {}

  changeState(state: RECHARGE_STATE) {
    this.rechargeStateNotifier.emit(AppStateService.getAppState(state));
  }

  getRechargesConfig(): Promise<ConfigData> { // only once
    if (this.configPromise) { return this.configPromise; }
    this.configPromise = new Promise<ConfigData>((resolve, reject) => {
      this.httpClient.get<any>(this.appRestService.config.rechargesBackendUrl + this.ENDPOINT_UTILITY_CONFIG)
        .subscribe(response => {
          this.configData = <ConfigData>response;
          resolve(this.configData);
        },() => reject());
    });
    return this.configPromise;
  }

  setupAmountForm(formGroup: UntypedFormGroup) {
    if (!this.cookieDataService.restoreAmountForm(formGroup))
      formGroup.get('amount').setValue(this.configData.amount.default);
  }

  setupPaymentForm(formGroup: UntypedFormGroup) {
    this.cookieDataService.restorePaymentForm(formGroup);
  }

  private static getAppState(state: RECHARGE_STATE) {
    switch (state) {
      case RECHARGE_STATE.AMOUNT: return RECHARGE_STATE_NAME.AMOUNT;
      case RECHARGE_STATE.PAYMENT: return RECHARGE_STATE_NAME.PAYMENT;
      case RECHARGE_STATE.BLIK: return RECHARGE_STATE_NAME.BLIK;
    }
    return RECHARGE_STATE_NAME.UNKNOWN;
  }

  // private static processConfigResponse(response: any): ConfigData {
  //   let config = new ConfigData();
  //   for (let property of Reflect.ownKeys(config)) {
  //     const objectProp = Reflect.getOwnPropertyDescriptor(config, property);
  //     const responseVal = response[property];
  //     if (responseVal) {
  //       if (!Array.isArray(objectProp.value)) {
  //         config[property] = Number.isInteger(objectProp.value) ? +responseVal[0] : responseVal[0];
  //       } else {
  //         config[property] = responseVal;
  //       }
  //     }
  //   }
  //   return config;
  // }

}
