
<section class="topup-section container">
  <div class="row align-center" >
    <div class="topup-container text-center">
      <div class="topup-payment inner-wrapper">

        <form [formGroup]="blikForm" (ngSubmit)="onFormSubmit()"
              [ngClass]="{ 'height-anim-closing': isSubmitted}">
          <img class="blik-logo" src="assets/images/payment_blik.svg" alt="BLIK">
          <div class="blik-form text-center">

            <div class="blik-code input-with-floating-label"
                 [ngClass]="{'error' : isBlikInvalid()}">
              <input type="text" id="blik-code" class="blik-code"
                     placeholder="" formControlName="blik" #blikInput>
              <label for="blik-code" i18n="inputBlikCode">Wprowadź kod BLIK</label>
              <small *ngIf="isBlikInvalid()"
                     class="form-message" role="alert">{{ getBlikErrorMsg() }}</small>
            </div>
          </div>

          <div class="steps">
            <button type="submit" id="submit-step2" class="button medium no-margin" i18n="go">
              Dalej <span class="icon icon-chevron-right"></span></button>
            <button id="step-back" type="button" class="button medium transparents-white no-margin"
                    (click)="onReturnBtn()" i18n="Back">Powrót</button>
          </div>
        </form>
        <img *ngIf="isSubmitted" src="assets/images/loader.gif" alt="loader" />
      </div>
    </div>
  </div>
</section>


