import {Directive, ElementRef, HostListener} from "@angular/core";

@Directive()
export class NumberValidator {

  readonly FUNCTION_KEYS = [97, 99, 118, 120, 122]; // a, c, v, x, z

  private isCtrlPressed = false;
  protected isWhitespaceAllowed = false;

  constructor(protected elementRef: ElementRef) {}

  @HostListener('keypress', ['$event'])
  onlyNumberKey(event: any) { // 8 BACKSPACE, 0 NULL
    if (event.charCode == 8 || event.charCode == 0) return null;
    return (event.charCode >= 48 && event.charCode <= 57)
      || (this.isWhitespaceAllowed && event.charCode == 32)
      || this.isSpecialKeyPressed(event.charCode);
  }

  protected isSpecialKeyPressed(char) {
    return this.isCtrlPressed && this.FUNCTION_KEYS
      .find(specialChar => specialChar == char || specialChar - 32 == char)
  }

  // document

  @HostListener('window:keydown', ['$event'])
  onDocumentKeyDown(event: any) {
    if (event.keyCode == 17) this.isCtrlPressed = true;
  }

  @HostListener('window:keyup', ['$event'])
  onDocumentKeyUp(event: any) {
    if (event.keyCode == 17) this.isCtrlPressed = false;
  }

  protected valueChangedEvent(event, value) {
    event.target.value = value;
    this.elementRef.nativeElement.dispatchEvent(new Event('input'));
  }

  protected inputChangedEvent(value) {
    this.setInputValue(value);
    this.elementRef.nativeElement.dispatchEvent(new Event('input'));
  }

  protected setInputValue(value, event = true) {
    this.elementRef.nativeElement.value = value;
    if (event) this.elementRef.nativeElement.dispatchEvent(new Event('input'));
  }

  protected isNumberKey(charCode) {
    return (charCode >= 48 && charCode <= 57);
  }

}
