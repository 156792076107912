<div >
<section class="topup-section container">
  <div class="row topup-helpers">
    <div class="small-12 large-2 large-offset-2 columns">
      <a href="https://doladowania.payu.pl/faktura">
        <div class="wrp">
          <span class="icon icon-invoice-new"><!-- ! --></span>
          <span i18n="OrderInvoice">Zamów fakturę</span>
        </div>
      </a>
    </div>
    <div class="small-12 large-2 columns">
      <a href="https://doladowania.payu.pl/reklamacje">
        <div class="wrp">
          <span class="icon icon-returns"><!-- ! --></span>
          <span i18n="complaint">Zgłoś reklamację</span>
        </div>
      </a>
    </div>

    <div class="small-12 large-2 columns" *ngIf="!this.restService.isVirgin()">
      <a href="https://doladowania.payu.pl/regulamin.pdf">
        <div class="wrp">
          <span class="icon icon-regulations"><!-- ! --></span>
          <span  i18n="@@pageRegulationPlay">Regulamin strony doladowania.play.pl</span>
        </div>
      </a>
    </div>

    <div class="small-12 large-2 columns" *ngIf="this.restService.isVirgin()">
      <a href="https://www.doladowania.payu.pl/regulamin.pdf">
        <div class="wrp">
          <span class="icon icon-regulations"><!-- ! --></span>
          <span i18n="@@pageRegulationVirgin">Regulamin strony doladowania.virginmobile.pl</span>
        </div>
      </a>
    </div>

    <div class="small-12 large-2 columns" *ngIf="!this.restService.isVirgin()">
      <a href="https://www.play.pl/pomoc/na-karte/jak-doladowac-karte.html">
        <div class="wrp">
          <span class="icon icon-help-new"><!-- ! --></span>
          <span i18n="gotoHelpPage">Przejdź do sekcji pomocy</span>
        </div>
      </a>
    </div>
  </div>
</section>
<div class="row align-center" *ngIf="!this.restService.isVirgin()">
  <div class="small-12 large-4" style="display: flex; justify-content: left;margin-right: 10px" *ngIf="bannerPrefsLeft != null && checkBanner(bannerPrefsLeft.src)">
    <a [href]="bannerPrefsLeft.link" target="_blank">
      <img style="box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, .15)"
           [src]="bannerPrefsLeft.src"
           [alt]="bannerPrefsLeft.alt">
    </a>
  </div>

  <div class="small-12 large-4" style="display: flex; justify-content: right" *ngIf="bannerPrefsRight != null && checkBanner(bannerPrefsRight.src)">
    <a [href]="bannerPrefsRight.link" target="_blank">
      <img style="box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, .15)"
           [src]="bannerPrefsRight.src"
           [alt]="bannerPrefsRight.alt">
    </a>
  </div>
</div>
</div>
