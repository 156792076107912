import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {AppRestService} from "./shared/services/app-rest.service";
import {AmountPickerComponent} from './recharge/amount-section/amount-selector/amount-picker.component';
import {AmountValidatorDirective} from './shared/directives/amount-validator.directive';
import {GiftBannerComponent} from './recharge/amount-section/gift-banner/gift-banner.component';
import {PaymentSectionComponent} from './recharge/payment-section/payment-section.component';
import {AmountSectionComponent} from './recharge/amount-section/amount-section.component';
import {PaymentMethodsComponent} from './recharge/payment-section/payment-methods/payment-methods.component';
import {RegulationsComponent} from './recharge/payment-section/regulations/regulations.component';
import {CookieService} from 'ngx-cookie-service';
import {CommaPipePipe} from './shared/pipes/comma-pipe.pipe';
import {GiftAmountPipe} from './shared/pipes/gift-amount.pipe';
import {DecimalPipe} from "@angular/common";
import {RouterModule, Routes} from "@angular/router";
import {RechargeComponent} from './recharge/recharge.component';
import {StatusComponent} from './status/status.component';
import {HelperFeedComponent} from "./shared/components/helper-feed/helper-feed.component";
import {
  TransferMethodComponent
} from './recharge/payment-section/payment-methods/transfer-method/transfer-method.component';
import {BlikMethodComponent} from './recharge/payment-section/payment-methods/blik-method/blik-method.component';
import {AppStateService} from "./shared/services/app-state.service";
import {BlikSectionComponent} from "./recharge/blik-section/blik-section.component";
import {DynamicComponent} from './shared/components/dynamic.component';
import {PageComponentService} from "./shared/services/page-component.service";
import {ButtonResizerDirective} from './shared/directives/button-resizer.directive';
import {FocusableDirective} from './shared/directives/focusable.directive';

export const routes: Routes = [
  { path: '', component: RechargeComponent },
  { path: 'statusPage', component: StatusComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
    declarations: [
        AppComponent,
        AmountPickerComponent,
        AmountValidatorDirective,
        GiftBannerComponent,
        PaymentSectionComponent,
        AmountSectionComponent,
        PaymentMethodsComponent,
        RegulationsComponent,
        CommaPipePipe,
        GiftAmountPipe,
        RechargeComponent,
        StatusComponent,
        HelperFeedComponent,
        TransferMethodComponent,
        BlikMethodComponent,
        BlikSectionComponent,
        DynamicComponent,
        ButtonResizerDirective,
        FocusableDirective
    ],
    imports: [
        RouterModule.forRoot(routes, {}),
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    providers: [
        AppRestService,
        AppStateService,
        PageComponentService,
        DecimalPipe,
        GiftAmountPipe,
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
