import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from "@angular/common";

@Pipe({
  name: 'giftAmount'
})
export class GiftAmountPipe implements PipeTransform {

  static GIFT_AMOUNT_THRESHOLD = 25;
  static GIFT_AMOUNT_VALUE = 0.15;

  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: any, args?: any): any {
    if (value < GiftAmountPipe.GIFT_AMOUNT_THRESHOLD) return null;
    let amount = value * GiftAmountPipe.GIFT_AMOUNT_VALUE;
    let strVal = String(this.decimalPipe.transform(amount, '0.0-2'));
    if (!strVal.indexOf(".")) return value;
    return strVal.replace(".", ",");
  }

}
