
<div id="service-regulations" class="custom-checkbox small text-left"
     [ngClass]="{'error' : paymentInvalid}">
  <input class="payment-agreement" id="payment-agreement" type="checkbox"
         (click)="onAgreementSelected($event.target.checked)" focusable >
  <label for="payment-agreement" i18n="acceptRegulation">
    Akceptuję <a class="underline" href="javascript:void(0)"
                 (click)="onToggleConsent()">regulamin doładowań</a>
  </label>
  <small *ngIf="paymentInvalid"
         role="alert" class="form-message" i18n="AcceptRegulationInvalid">Zaakceptuj regulamin, aby przejść dalej</small>
  <div style="margin-left: 1rem" [hidden]="!showConsent">
    <small style="display: block" [innerHTML]="consentHtml"></small>
    <small style="display: block; margin: 1rem 0" [innerHTML]="regulationContent"></small>
  </div>
</div>
