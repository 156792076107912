
<ol class="value-selector">
  <li *ngFor="let amountValue of definedAmountsList; let i = index">
    <input class="topup-value" name="topup-amount" type="radio" data-type="basic" #amountValueInput
           [id]="'amount-value-' + i" [value]="amountValue" (click)="setAmountValue($event, amountValue)">
    <label [for]="'amount-value-' + i" [attr.data-amount]="amountValue + getAmountSuffix(amountValue)"></label>
  </li>
</ol>

<div [hidden]="!isCustomAmountVisible">
  <div class="custom-value input-with-floating-label" [ngClass]="{'incorrect': isAmountInputInvalid()}"
       style="display: block" [formGroup]="formGroup">
    <input type="tel" id="custom-value" name="topups-amount-custom-1" formControlName="amount"
           placeholder="Wpisz kwotę doładowania ({{ amountConfig.min }} - {{ amountConfig.max }} zł)"
           amountValidator [minAmount]="amountConfig.min" [maxAmount]="amountConfig.max">
    <label for="custom-value" i18n="AmountValue">Wpisz kwotę doładowania
      ({{ amountConfig.min }}<span>-</span><span class="sr-only">do</span>{{ amountConfig.max }} zł)</label>
    <span class="error-msg-maxvalue">{{ getAmountErrorMsg() }}</span>
  </div>
</div>
